import React, { useEffect, useState } from "react";
import axios from "axios";
import FullPageLoader from "../Loaders/fullpageloader";

function Privacy() {
  const [data, setData] = useState("");
  const [loader, setLoader] = useState(false);

  const fetchData = async() =>{
    let res = await axios.get(`${process.env.REACT_APP_ADMIN_API}/site_settings`);
    if(res){
      setData(res.data.data.privacy_content)
      setLoader(false);
    }
  }

  useEffect(() =>{
    setLoader(true)
    fetchData();
		// eslint-disable-next-line
  },[])

  return (
    <>
      {
        loader ? (
          <>
            <FullPageLoader/>
          </>
        )
        : data ? 
        (
          <div className="container">
            <div className="section_padding">
              <div className="terms-page">
                <div className="page-heading my-lg-4 my-2">
                  <h2 className="text-white text-capitalize mt-4 text-center">
                    Privacy policy
                  </h2>
                </div>
                <p dangerouslySetInnerHTML={{ __html: data }}></p>
              </div>
            </div>
        </div>
        )
        :
        (
          <div className="text-center">
            <span className='text-light text-center'>No Record Found</span>
          </div>
        )
      }
      
    </>
  );
}

export default Privacy;
