/* eslint-disable */
import { ethers, BigNumber } from 'ethers';
import Web3 from 'web3';
import TokenAbi from './token20Abi';

export function RPCConnector(a, b) { }
export function TimeConverter(UNIX_timestamp) {
  var a = new Date(UNIX_timestamp * 1000);
  let time = {};
  var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate();
  var hour = a.getHours();
  var min = a.getMinutes();
  // var sec = a.getSeconds()

  var time1 = date + ' ' + month + ' ' + year;
  time.month = month;
  time.hour = hour;
  time.min = min;
  time.date = date;

  return time1;
}

export async function FetchSigner(address, Abi) {
  if (window.ethereum !== undefined) {
    const provider = new ethers.providers.Web3Provider(window.ethereum);

    const signer = await provider.getSigner();

    var contract = new ethers.Contract(address, Abi, signer);
    return contract;
  } else {
    // console.log('Error Metamask is not installed')
  }
}
export function DefualtImageSetter(e) {
  e.target.src = 'https://media.istockphoto.com/photos/abstract-graphic-world-map-illustration-on-blue-background-big-data-picture-id1294021851';
}
export async function CustomRpcProvider(address, Abi, network) {
  return new ethers.Contract(address, Abi);
}

export function toWeiValue(_value = 0, _decimal = 18) {
  _value = _value.toString();
  let weiUnit = 'ether';
  switch (parseInt(_decimal)) {
    case 9:
      weiUnit = 'gwei';
      break;
    case 6:
      weiUnit = 'mwei';
      break;
    default:
      weiUnit = 'ether';
      break;
  }
  return Web3.utils.toWei(_value, weiUnit);
}


export function frommWeiValue(_value, _decimal = 18) {
  _value = _value.toString();
  let weiUnit = 'ether';
  switch (parseInt(_decimal)) {
    case 9:
      weiUnit = 'gwei';
      break;
    case 6:
      weiUnit = 'mwei';
      break;
    default:
      weiUnit = 'ether';
      break;
  }
  return Web3.utils.fromWei(_value, weiUnit);
}

/*CLEARING PRICE*/

export const queueStartElement = '0x0000000000000000000000000000000000000000000000000000000000000001';
export const queueLastElement = '0xffffffffffffffffffffffffffffffffffffffff000000000000000000000001';

export function gGetClearingPriceFromInitialOrder(order) {
  return {
    userId: BigNumber.from(0),
    sellAmount: order.buyAmount,
    buyAmount: order.sellAmount,
  };
}
export function gEncodeOrder(order) {
  return '0x' + order.userId.toHexString().slice(2).padStart(16, '0') + order.buyAmount.toHexString().slice(2).padStart(24, '0') + order.sellAmount.toHexString().slice(2).padStart(24, '0');
}
export function gDecodeOrder(bytes) {
  if (bytes) {
    return {
      userId: BigNumber.from('0x' + bytes?.substring(2, 18)).toString(),
      sellAmount: BigNumber.from('0x' + bytes?.substring(43, 66)).toString(),
      buyAmount: BigNumber.from('0x' + bytes?.substring(19, 42)).toString(),
    };
  }
}
export async function gGetInitialOrder(poolContract, poolId) {
  const poolDataStruct = await poolContract.methods.poolData(poolId).call();
  return gDecodeOrder(poolDataStruct.initialPoolOrder);
}
export async function gGetInterimOrder(poolContract, poolId) {
  const poolDataStruct = await poolContract.methods.poolData(poolId).call();
  return gDecodeOrder(poolDataStruct.interimOrder);
}
export function hasLowerClearingPrice(order1, order2) {
  if (order1.buyAmount * order2.sellAmount < order2.buyAmount * order1.sellAmount) return -1;
  if (order1.buyAmount < order2.buyAmount) return -1;
  if (order1.buyAmount * order2.sellAmount === order2.buyAmount * order1.sellAmount) {
    if (order1.userId < order2.userId) return -1;
  }
  return 1;
}
export async function calculateClearingPrice(poolContract, poolId, debug = false) {
  const initialOrder = await gGetInitialOrder(poolContract, poolId);
  const sellOrders = await gGetAllSellOrders(poolContract, poolId);
  sellOrders.sort(function (a, b) {
    return hasLowerClearingPrice(a, b);
  });

  const clearingPriceOrder = gFindClearingPrice(sellOrders, initialOrder);
  const interimOrder = await gGetInterimOrder(poolContract, poolId);
  let numberOfOrdersToClear;
  if (
    interimOrder ===
    {
      userId: 0,
      sellAmount: 0,
      buyAmount: 0,
    }
  ) {
    numberOfOrdersToClear = sellOrders.filter((order) => hasLowerClearingPrice(order, clearingPriceOrder)).length;
  } else {
    numberOfOrdersToClear = sellOrders.filter((order) => hasLowerClearingPrice(order, clearingPriceOrder) && hasLowerClearingPrice(interimOrder, order)).length;
  }

  return {
    clearingOrder: clearingPriceOrder,
    numberOfOrdersToClear,
  };
}

export function gFindClearingPrice(sellOrders, initialAuctionOrder, btDecimal = 18) {
  sellOrders.forEach(function (order, index) {
    if (index > 1) {
      if (!hasLowerClearingPrice(sellOrders[index - 1], order)) {
        throw Error('The orders must be sorted');
      }
    }
  });
  let totalSellVolume = 0;
  let finalReturn = 0;

  for (const order of sellOrders) {
    totalSellVolume = totalSellVolume + order.sellAmount;
    if ((totalSellVolume * order.buyAmount) / order.sellAmount > initialAuctionOrder.sellAmount) {
      const coveredBuyAmount = ((initialAuctionOrder.sellAmount - totalSellVolume - order.sellAmount) * order.buyAmount) / order.sellAmount;
      const sellAmountClearingOrder = (coveredBuyAmount * order.sellAmount) / order.buyAmount;
      if (sellAmountClearingOrder > 0) {
        finalReturn = (sellOrders[0].sellAmount * order.buyAmount) / order.sellAmount / Math.pow(10, btDecimal);
      } else {
        finalReturn = (sellOrders[0].sellAmount * initialAuctionOrder.sellAmount) / (totalSellVolume - order.sellAmount) / Math.pow(10, btDecimal);
      }
    }
  }
  // otherwise, clearing price is initialAuctionOrder
  if (totalSellVolume > initialAuctionOrder.buyAmount) {
    finalReturn = (sellOrders[0].sellAmount * initialAuctionOrder.sellAmount) / totalSellVolume / Math.pow(10, btDecimal);
  } else {
    finalReturn = (sellOrders[0].sellAmount * initialAuctionOrder.buyAmount) / initialAuctionOrder.sellAmount / Math.pow(10, btDecimal);
  }

  if (isNaN(finalReturn)) {
    return 0;
  }
  return finalReturn.toFixed(6);
}
export async function gGetAllSellOrders(poolContract, poolId) {
  const filterSellOrders = poolContract.events.NewSellOrderPlaced(poolId, null, null, null);
  const logs = await poolContract.queryFilter(filterSellOrders, 0, 'latest');
  const events = logs.map((log) => poolContract.interface.parseLog(log));
  const sellOrders = events.map((x) => {
    const order = {
      userId: x.args[1],
      sellAmount: x.args[3],
      buyAmount: x.args[2],
    };
    return order;
  });

  const filterOrderCancellations = poolContract.events.SellOrderCancelled;
  const logsForCancellations = await poolContract.queryFilter(filterOrderCancellations(), 0, 'latest');
  const eventsForCancellations = logsForCancellations.map((log) => poolContract.interface.parseLog(log));
  const sellOrdersDeletions = eventsForCancellations.map((x) => {
    const order = {
      userId: x.args[1],
      sellAmount: x.args[3],
      buyAmount: x.args[2],
    };
    return order;
  });
  for (const orderDeletion of sellOrdersDeletions) {
    sellOrders.splice(sellOrders.indexOf(orderDeletion), 1);
  }
  return sellOrders;
}

export const objectToQueryString = function(body) {
  const qs = Object.keys(body).map(key => `${key}=${body[key]}`).join('&');
  return qs;
}

const FetchProvider = async (tokenAdd, Abi) => {
  const provider = new ethers.providers.Web3Provider(window.ethereum || process.env.REACT_APP_TESTNET_RPC_URL);
  const signer = provider.getSigner();
  var address = tokenAdd;
  var contract = new ethers.Contract(address, Abi, signer);
  return contract;
};

export const tokensAllInfo = async tok => {
  const contract = await FetchProvider(tok, TokenAbi);
  const Decimals = parseInt(await contract.decimals());
  const decimalConvert = Math.pow(10, Decimals);
  const name = await contract.name();
  const symbol = await contract.name();
  let obj ={
    name:name,symbol:symbol,decimals:decimalConvert
  }
  return obj;
};

export const getRecieptData = async(trxHash, sellTok) => {
  const web3 = new Web3(window.ethereum);
  let data = await web3.eth.getTransactionReceipt(trxHash);
  if(data?.logs?.length > 2){
    let clmLgData = data?.logs[1];
    let cmAdd = clmLgData.address;
    const {symbol, decimals} = await tokensAllInfo(cmAdd)
    let clmDataHash = clmLgData?.data
    let clmTransferred = ethers.utils.defaultAbiCoder.decode(['uint256'], clmDataHash)
    clmTransferred =  (clmTransferred[0])/decimals;
    let rfdLgData = data?.logs[2];
    let rfdAdd = rfdLgData.address;
    let rfdTokenInfo = await tokensAllInfo(rfdAdd)
    let rfdDataHash = rfdLgData?.data
    let rfdTransferred = ethers.utils.defaultAbiCoder.decode(['uint256'], rfdDataHash)
    rfdTransferred =  (rfdTransferred[0])/rfdTokenInfo?.decimals;
    let trxStats ={
      claimedValue:clmTransferred,claimedToken:symbol,claimedType:"claimed&refund", 
      refundedValue:rfdTransferred, refundedToken:rfdTokenInfo.symbol
    }
    return trxStats;
  } else {
    let add = data?.logs[1]?.address;
    const {symbol, decimals} = await tokensAllInfo(add)
    let logsData = data?.logs[1];
    let dataHash = logsData?.data
    let transferredValue = ethers.utils.defaultAbiCoder.decode(['uint256'], dataHash)
    transferredValue =  (transferredValue[0])/decimals;
    let trxStats;
    if(symbol !== sellTok){
      trxStats={
        claimedToken:symbol,claimedValue:transferredValue, claimedType:"claimed"
      }
    } else {
      trxStats={
        refundedToken:sellTok,refundedValue:transferredValue, claimedType:"refunded"
      }
    }
    return trxStats;
  }
 
}