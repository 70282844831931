import { GET_SETTING, UPDATE_SETTING, UPDATE_STATE } from './setting.types';

const initialState = {
  setting: [],
  success: false
};

const settingReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SETTING:
      return {
        ...state,
        setting: action.payload?.settingsDocument[0]
      };
    case UPDATE_SETTING:
      return {
        ...state,
        setting: action.payload,
        success: true
      };
    case UPDATE_STATE:
      return {
        ...state,
        success: false,
      };
    default:
      return {
        ...state
      };
  }
};

export default settingReducer;