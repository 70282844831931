import React, { useState } from 'react'
import { Modal, Button, Form } from 'react-bootstrap'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { addFAQ } from '../../../redux/faq/faq.action';
import { useDispatch } from 'react-redux';

function FaqModal({ show, handleModal }) {

  const dispatch = useDispatch()
  const [faqs, setFaqs] = useState({ title: '', description: '' })

  const handleClose = () => {
    handleModal()
  }

  const handleFaqSubmit = () => {
    const formData = {
      title: faqs.title,
      description: faqs.description
    }
    dispatch(addFAQ(formData))
    handleClose()
  }

  return (
    <>
      <Modal show={show} onHide={handleClose} className='cms-pages-style'
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton className='faq-modal'>
          <Modal.Title>Manage Faq's</Modal.Title>
          <button
            type='button'
            className='close-btn'
            onClick={handleClose}
            aria-label='Close'
          >
            ×
          </button>
        </Modal.Header>
        <Modal.Body className='faq-modal'>
          <Form className='form-border'>
            <Form.Group className='mb-3' controlId='FaqTitle'>
              <Form.Label>Faq Title</Form.Label>
              <Form.Control
                type='text'
                name='title'
                value={faqs.title}
                onChange={(e) => setFaqs({ ...faqs, title: e.target.value })}
                placeholder='Enter Faq Title'
                required
              />
            </Form.Group>
            <Form.Group>
              <div className="ido-ckeditor pt-lg-4 pt-3">
                <CKEditor editor={ClassicEditor} data={faqs.description == null ? '' : faqs.description} onChange={(event, editor) => {
                  const data = editor.getData();
                  setFaqs({ ...faqs, description: data })
                }} />
              </div>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer className='faq-modal border-top-0'>
          <Button
            className='blue-imp light-blue-btn text-capitalize'
            type='submit'
            onClick={handleFaqSubmit}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default FaqModal