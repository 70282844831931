import React from 'react';

const YourProject = () => {
    return <>
        <section className="your-project">
            <div className="container-fluid custom-block">
                <div className="project-bg">
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="width-wrap">
                            <p className="text-uppercase space-p">Ready to launch</p>
                            <h2 className="h2 text-uppercase">your project on MUTOPAD?</h2>
                        </div>
                        <a className='d-inline theme-btn' href='https://docs.google.com/forms/d/e/1FAIpQLSerckCKkf5LDX8oOg4IK9UdTYIlXdVEQzxlVYsKrHQKzGoV-g/viewform'>
                            <span>Apply To Launch</span>
                        </a>
                    </div>
                </div>
            </div>
        </section>
    </>
};

export default YourProject;
