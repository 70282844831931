import { FAQ_LISTING, ADD_FAQ, FAQ_BY_ID, UPDATE_FAQ, DELETE_FAQ, UPDATE_STATE } from './faq.types';

const initialState = {
  faqsList: [],
  faq: [],
  success: false
};

const faqReducer = (state = initialState, action) => {
  switch (action.type) {
    case FAQ_LISTING:
      return {
        ...state,
        faqsList: action.payload.data.faqs
      };
    case ADD_FAQ:
      return {
        ...state,
        faqsList: [...state.faqsList, action.payload],
      };
    case FAQ_BY_ID:
      return {
        ...state,
        faq: action.payload
      };
    case UPDATE_FAQ:
      return {
        ...state,
        faq: action.payload,
        updatedCall: true
      };
    case DELETE_FAQ:
      return {
        ...state,
        faqsList: state.faqsList.filter((faq) => faq._id !== action.payload),
        success: true
      };
    case UPDATE_STATE:
      return {
        ...state,
        success: false,
      };
    default:
      return {
        ...state
      };
  }
};

export default faqReducer;