import React from 'react';
import '../../assets/css/style.css';
import { Container, Row } from 'react-bootstrap';
import Lottie from 'react-lottie';
import animationData from '../../assets/js/404-lottie.json';

const PageNotFound = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  return (
    <Container>
      <Row className='justify-content-center align-items-center'>
        <div className='col-12'>
          <div className='not-found-lottie text-center'>
            <Lottie options={defaultOptions}/>
          </div>
        </div>
      </Row>
    </Container>
  );
};

export default PageNotFound;
