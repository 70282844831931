import React, { useEffect, useState } from 'react'
// import { TailSpin } from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { cmsSlugPage } from '../../../redux/cms/cms.action';
import FullPageLoader from '../../Loaders/fullpageloader';

const CMSPageView = () => {

   const { pageTitle } = useParams()
   const dispatch = useDispatch()
   const [loader, setLoader] = useState(false);
   const cmsPage = useSelector((state) => state?.cms?.cmsSlugPage)

   const fetchData = async () => {
      setLoader(true)
      if (pageTitle) {
         setLoader(true)
         let res = await dispatch(cmsSlugPage(pageTitle))
         if (res) {
            setLoader(false)
         }
      }
   }

   useEffect(() => {
      if (pageTitle) {
         fetchData()
      }
      // eslint-disable-next-line
   }, [pageTitle]);

   return (
      <>
         {loader ? (
               <FullPageLoader/>
         ) : (
            <>
               {cmsPage !== '' && cmsPage !== undefined ?
                  <div className="container">
                     <div className="section_padding">
                        <div className="terms-page">
                           <div className="page-heading my-lg-4 my-2">
                              <h2 className="text-white text-capitalize mt-4 text-center">
                                 {cmsPage.pageTitle?.split("-").join(" ")}
                              </h2>
                           </div>
                           <p dangerouslySetInnerHTML={{ __html: cmsPage.pageData }}></p>
                        </div>
                     </div>
                  </div>
                  :
                  <>
                     <p>No Content</p>
                  </>
               }
            </>
         )}
      </>
   );
}

export default CMSPageView