import React, { useEffect, useState } from 'react'
import Card from 'react-bootstrap/Card'
import { Accordion } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import FaqModal from './addfaqmodal'
import EditFaqModal from './editfaq'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDispatch, useSelector } from 'react-redux'
// import { TailSpin } from 'react-loader-spinner'
import * as FAQActions from "../../../redux/faq/faq.action";
import { bindActionCreators } from 'redux'
import FullPageLoader from '../../Loaders/fullpageloader'

function ManageFaq() {

	const dispatch = useDispatch()
	const { faqListing, deleteFAQ, updateState } = bindActionCreators(FAQActions, dispatch)
	const faqs = useSelector((state) => state.faq?.faqsList)
	const success = useSelector((state) => state.faq?.success)
	const [loader, setLoader] = useState(true);
	const [show, setShow] = useState(false)
	const [editModal, setEditModal] = useState('')
	const handleModal = () => {
		setShow(false)
		setEditModal('')
	}
	const handleEditModal = () => {
		setEditModal('')
		setShow(false)
	}

	const fetchData = async () => {
		setLoader(true)
		let res = await faqListing()
		if (res) {
			setLoader(false)
		}
	}

	useEffect(() => {
		fetchData()
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		faqListing()
		// eslint-disable-next-line
	}, [show, editModal]);

	const AddFaqHandler = () => {
		setShow(true)
		setEditModal('')
	}

	const EditFaqHandler = item => {
		setEditModal(item)
		setShow(false)
	}
	useEffect(() => {
		if (success) {
			faqListing()
			updateState()
		}
		// eslint-disable-next-line
	}, [success]);

	return (
		<>
			<div className='mutopad-w-100'>
				{loader ? (
					<Card className="pt-lg-4 pt-3 h-auto" id="style-6">
						<Card.Body>
							<div className="text-center faq-loader">
								{/* <TailSpin height="100" width="100" color="#46bdf4" ariaLabel="loading" /> */}
								<FullPageLoader/>
							</div>
						</Card.Body>
					</Card>
				) : (
					<div>
						<div className='content faqs-pg'>
							<Card className='pt-lg-4 pt-3' id="style-6">
								<Card.Header className='titles-sett'>
									<h2 className='text-shadow'>Manage FAQS</h2>
									<div className='sm-right-btn'><button
										className='d-inline light-blue-btn text-capitalize'
										onClick={AddFaqHandler}
									>
										<FontAwesomeIcon className='add-icon' icon={faPlus} /> Add Faq
									</button></div>
								</Card.Header>
								<Card.Body>
									{faqs?.length > 0 ? (
										faqs.map((item, index) => {
											return (
												<Accordion key={index} className="manage-faqs-accordian">
													<Accordion.Item className='border-0' eventKey="0">
														<div className='manage-pools p-0'>
															<Accordion.Header>
																<div className='card-header w-100'>
																	<div className='manage-qstns manage-faqs'>
																		<div className='manage-faqs-content'>
																			<h4 className='card-title text-white mb-0 d-flex align-items-center'>
																				<div className='me-2'><span className='qstn-span'>{'Q'} </span></div> &nbsp; <div>{item.title}</div>
																			</h4>
																		</div>
																		<ul className='nav nav-pills card-header-pills faqs-admn-styling mx-2'>
																			<li className='nav-item text-white'>
																				{/* <p className='nav-link text-white'>EDIT</p> */}
																				<Link to="#" onClick={() => EditFaqHandler(item)} className="icon-btn shadow-btn text-uppercase">edit</Link>
																			</li>
																			<li className='nav-item border-primary green-box'>
																				{/* <p onClick={() => DeleteFaqHandler(item)} className='nav-link text-white light-blue-btn text-uppercase'>DELETE </p> */}
																				<Link to="#" onClick={() => { deleteFAQ(item._id) }} className="light-blue-btn text-uppercase">delete</Link>
																			</li>
																		</ul>
																	</div>
																</div>
															</Accordion.Header>
															<Accordion.Body>
																<div className='faqs-wraper'>
																	<div className='card-body text-white'>
																		<p className='card-text' dangerouslySetInnerHTML={{ __html: item.description }}></p>
																	</div>
																</div>
															</Accordion.Body>
														</div>
													</Accordion.Item>
												</Accordion>
											)
										})
									) : (
										<div className="text-center">
											<span className='text-light text-center'>No Record Found</span>
										</div>
									)
									}
								</Card.Body>
							</Card>
						</div>

						<div
							className='modal fade'
							id='exampleModal'
							tabIndex='-1'
							role='dialog'
							aria-labelledby='exampleModalLabel'
							aria-hidden='true'
						>
							<div className='modal-dialog' role='document'>
								<div className='modal-content'>
									<div className='modal-header'>
										<h5 className='modal-title' id='exampleModalLabel'>
											Modal title
										</h5>
										<button
											type='button'
											className='close'
											data-dismiss='modal'
											aria-label='Close'
										>
											<span aria-hidden='true'>&times;</span>
										</button>
									</div>
									<div className='modal-body'>...</div>
									<div className='modal-footer'>
										<button
											type='button'
											className='btn btn-secondary'
											data-dismiss='modal'
										>
											Close
										</button>
										<button type='button' className='btn btn-primary'>
											Save changes
										</button>
									</div>
								</div>
							</div>
						</div>
						{show && <FaqModal show={show} handleModal={handleModal} />}
						{editModal !== '' ? (
							<EditFaqModal
								show={true}
								handleEditModal={handleEditModal}
								data={editModal}
							/>
						) : (
							<></>
						)}
					</div>
				)}
			</div>
		</>
	)
}

export default ManageFaq