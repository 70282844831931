import axios from 'axios';
import { ENV } from '../config/config';
import { toast } from 'react-toastify';

if (window.location.hostname === 'localhost') {
  var baseUrl = ENV.serverUrl;
} else {
  baseUrl = ENV.serverUrlLive;
}

async function apiHelper(apiType, path, data, params) {
  if (baseUrl === undefined || !baseUrl) {
    baseUrl = '';
  }
  // const xauthtoken = JSON.parse(localStorage.getItem('token'));

  if (
    apiType === 'post' ||
    apiType === 'put' ||
    apiType === 'get' ||
    apiType === 'delete' ||
    apiType === 'patch'
  ) {
    try {
      let response = await axios({
        method: apiType,
        url: `${baseUrl + path}`,
        data
        // headers: {
        //     'x-access-token': xauthtoken,
        //     'x-auth-token': ENV.x_auth_token
        // }
      });
      return response;
    } catch (error) {
      toast.error(error);
      // console.log(error.response.data.message);
    }
  }
}

export { apiHelper };
