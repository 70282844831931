import { CREATE_CMS_PAGE, CMS_PAGE_LISTING, CMS_PAGE_BY_ID, UPDATE_CMS_PAGE, DELETE_CMS_PAGE, UPDATE_STATE, CMS_SLUG } from './cms.types';

const initialState = {
  cmsPageList: [],
  cmsPage: {},
  cmsSlugPage: {},
  success: false
};

const cmsPageReducer = (state = initialState, action) => {
  switch (action.type) {
    case CMS_PAGE_LISTING:
      return {
        ...state,
        cmsPageList: action.payload.data?.cms
      };
    case CREATE_CMS_PAGE:
      return {
        ...state,
        cmsPageList: [...state.cmsPageList, action.payload],
        success: true
      };
    case CMS_PAGE_BY_ID:
      return {
        ...state,
        cmsPage: action.payload?.cms
      };
    case CMS_SLUG:
      return {
        ...state,
        cmsSlugPage: action.payload?.cms
      };
    case UPDATE_CMS_PAGE:
      return {
        ...state,
        cmsPage: action.payload,
        success: true
      };
    case DELETE_CMS_PAGE:
      return {
        ...state,
        cmsPageList: state.cmsPageList.filter((cmsPage) => cmsPage._id !== action.payload),
        success: true
      };
    case UPDATE_STATE:
      return {
        ...state,
        success: false,
      };
    default:
      return {
        ...state
      };
  }
};

export default cmsPageReducer;