import { GET_SETTING, UPDATE_SETTING, UPDATE_STATE, ERROR_STATE } from './setting.types';
// import { toast } from 'react-toastify';
import { apiHelper } from '../apiHelper';

export const getSetting = () => async (dispatch) => {
  try {
    let res = await apiHelper('get', `/v1/admin/settings/get`);
    if (res?.data) {
      let { data } = res;
      dispatch({
        type: GET_SETTING,
        payload: data
      });
    } else {
      dispatch({
        type: ERROR_STATE
      });
    }
    return res.data.success
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const updateSetting = (data) => async (dispatch) => {
  try {
    let res = await apiHelper('put', `/v1/admin/settings/edit`, data);
    if (res?.data) {
      let { data } = res;
      // toast.success(res.data.message)
      dispatch({
        type: UPDATE_SETTING,
        payload: data
      });
    } else {
      dispatch({
        type: ERROR_STATE
      });
    }
    return res.data.success
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const updateState = () => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_STATE
    });
  } catch (error) {
    console.log(error);
  }
}
