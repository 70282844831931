import React from 'react';
import { Image } from 'react-bootstrap'
import accessIcon from "../../../src/assets/images/access.png";
import './productinfo.css';

const KYCMessagePage = () => {
    return (
        <div className='section_padding'>
            <div className='container'>
                <div className='kycmessage-box position-relative'>
                    <div className="profile-info-scam-text-content">
                        <div className="d-flex align-items-center justify-content-between mb-4">
                            <h5>KYC Disclaimer Notice</h5>
                            <div className="scamsmall-img">
                                <Image src={accessIcon} className="img-fluid" />
                            </div>
                        </div>
                        <p>Currently KYC is disabled from the admin, To continue this feature please contact with the admin.</p>
                        <h6><span>Note: </span>This message is from admin side</h6>
                        
                    </div>
                </div>
            </div>
        </div>
    )
}

export default KYCMessagePage
