import { COMMENTS_LISTING, ADD_COMMENT, COMMENTS_BY_POOL_ID, COMMENT_BY_ID, UPDATE_COMMENT, DELETE_COMMENT, ERROR_STATE, UPDATE_STATE } from './comment.types';
// import { toast } from 'react-toastify';
import { apiHelper } from '../apiHelper';

export const commentsListing = () => async (dispatch) => {
  try {
    let res = await apiHelper('get', `/v1/front/comments/comment`);
    if (res?.data) {
      let { data } = res;
      dispatch({
        type: COMMENTS_LISTING,
        payload: data
      });
    } else {
      dispatch({
        type: ERROR_STATE
      });
    }
  } catch (error) {
    // toast.danger(error);
  }
};

export const addComment = (data) => async (dispatch) => {
  try {
    let res = await apiHelper('post', `/v1/front/comments/comment`, data);
    if (res?.data) {
      let { data } = res;
      // toast.success(res.data.message)
      dispatch({
        type: ADD_COMMENT,
        payload: data
      });
    } else {
      dispatch({
        type: ERROR_STATE
      });
    }
  } catch (error) {
    // toast.danger(error);
  }
};

export const commentsByPoolId = (id) => async (dispatch) => {
  try {
    let res = await apiHelper('get', `/v1/front/comments/commentsByPoolId/${id}`);
    if (res?.data) {
      let { data } = res;
      dispatch({
        type: COMMENTS_BY_POOL_ID,
        payload: data
      });
    } else {
      dispatch({
        type: ERROR_STATE
      });
    }
  } catch (error) {
    // toast.danger(error);
  }
};

export const commentsById = (id) => async (dispatch) => {
  try {
    let res = await apiHelper('get', `/v1/front/comments/comment/${id}`);
    if (res?.data) {
      let { data } = res;
      dispatch({
        type: COMMENT_BY_ID,
        payload: data
      });
    } else {
      dispatch({
        type: ERROR_STATE
      });
    }
  } catch (error) {
    // toast.danger(error);
  }
};

export const updateComment = (id, data) => async (dispatch) => {
  try {
    let res = await apiHelper('patch', `/v1/front/comments/comment/${id}`, data);
    if (res?.data) {
      let { data } = res;
      // toast.success(res.data.message)
      dispatch({
        type: UPDATE_COMMENT,
        payload: data
      });
    } else {
      dispatch({
        type: ERROR_STATE
      });
    }
  } catch (error) {
    // toast.danger(error);
  }
};

export const deleteComment = (id) => async (dispatch) => {
  try {
    let res = await apiHelper('delete', `/v1/front/comments/comment/${id}`);
    if (res?.data) {
      let { data } = res;
      // toast.success(res.data.message)
      dispatch({
        type: DELETE_COMMENT,
        payload: data
      });
    } else {
      dispatch({
        type: ERROR_STATE
      });
    }
  } catch (error) {
    // toast.danger(error)
  }
};

export const updateState = () => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_STATE
    });
  } catch (error) {
    // toast.danger(error);
  }
}
