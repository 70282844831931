import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import { Provider } from 'react-redux';
import store from './redux/combineReducers';
import { ToastContainer } from 'react-toastify';
import '@rainbow-me/rainbowkit/styles.css';
import {
  // injectedWallet,
  metaMaskWallet,
} from '@rainbow-me/rainbowkit/wallets';
import { connectorsForWallets, RainbowKitProvider} from '@rainbow-me/rainbowkit';
import { configureChains, createClient, WagmiConfig } from 'wagmi';
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { goerli } from 'wagmi/chains';
import { publicProvider } from 'wagmi/providers/public';
import { bscTestnet, bscMainnet } from './config/config';
import 'react-toastify/dist/ReactToastify.css';

const WrapWagmi = () => {
  const { chains, provider } = configureChains([goerli, bscTestnet, bscMainnet], [alchemyProvider({ apiKey: process.env.REACT_APP_ALCHEMY_ID }), publicProvider()]);
  const connectors = connectorsForWallets([
    {
      groupName: 'Recomended',
      wallets: [
        metaMaskWallet({ chains }),
      ],
    },
  ]);

  const wagmiClient = createClient({
    autoConnect: true,
    connectors,
    provider,
  });

  return (
    <WagmiConfig client={wagmiClient}>
      <RainbowKitProvider chains={chains} modalSize="compact" >
        <App />
      </RainbowKitProvider>
    </WagmiConfig>
  );
};

ReactDOM.render(
  <Provider store={store}>
    {/* <React.StrictMode> */}
    <ToastContainer />
    <BrowserRouter>
      <CookiesProvider>
        <WrapWagmi />
      </CookiesProvider>
    </BrowserRouter>
    {/* </React.StrictMode> */}
  </Provider>,
  document.getElementById('root'),
);
reportWebVitals();
