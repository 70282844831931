import React from 'react';
import { Modal, Form } from 'react-bootstrap'
import './editpool.css'

const ImageReviewerModal = ({show1, handleModal2, imgData}) => {

    const handleClose = () => {
        handleModal2()
    }

  return (
    <>
       <Modal show={show1} onHide={handleClose} className='product-modal d-flex align-items-center' backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
               <Modal.Title>Image</Modal.Title>
               {/* <button type='button' className='close-btn' onClick={handleClose} aria-label='Close'>×</button> */}
            </Modal.Header>
            <Modal.Body>
               <Form className='form-border'>
                  <div className='Image-sec'>
                    <img src={`https://ipfs.io/ipfs/${imgData}`} alt="doc" width='600' />
                  </div>
               </Form>
            </Modal.Body>
         </Modal>
    </>
  )
}

export default ImageReviewerModal
