import { COMMENTS_LISTING, ADD_COMMENT, COMMENTS_BY_POOL_ID, COMMENT_BY_ID, UPDATE_COMMENT, DELETE_COMMENT, UPDATE_STATE } from './comment.types';

const initialState = {
  commentsList: [],
  commentsByPoolId: [],
  comment: {},
  success: false,
  commentSuccess:false
};

const commentReducer = (state = initialState, action) => {
  switch (action.type) {
    case COMMENTS_LISTING:
      return {
        ...state,
        commentsList: action.payload
      };
    case ADD_COMMENT:
      return {
        ...state,
        commentsList: [...state.commentsList, action.payload],
        commentSuccess:true

      };
    case COMMENTS_BY_POOL_ID:
      return {
        ...state,
        commentsByPoolId: action.payload?.comments,
        // commentSuccess:true
      };
    case COMMENT_BY_ID:
      return {
        ...state,
        comment: action.payload
      };
    case UPDATE_COMMENT:
      return {
        ...state,
        comment: action.payload,
        updatedCall: true
      };
    case DELETE_COMMENT:
      return {
        ...state,
        commentsList: state.commentsList.filter((comment) => comment._id !== action.payload),
        success: true
      };
    case UPDATE_STATE:
      return {
        ...state,
        success: false,
        commentSuccess:false
      };
    default:
      return {
        ...state
      };
  }
};

export default commentReducer;