/* eslint-disable */
import React, { useState, useRef, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import 'react-datepicker/dist/react-datepicker.css'
import uploadImg from '../../../assets/images/photo.png'
import { Form, Button, Row } from 'react-bootstrap'
import TokenAbi from '../../../utils/token20Abi'
import './editpool.css'
import { create } from 'ipfs-http-client'
import Info from '../../InfoBtn/info'
import ErrorLabels from '../../ErrorLabels/errorlabels'
import 'flatpickr/dist/themes/material_green.css'
import ContractAbi from '../../../utils/contractAbi.json'
import Alerts from '../../Alerts/alert'
import Card from 'react-bootstrap/Card'
import Web3 from 'web3'
import { poolById, updatePoolUser } from '../../../redux/pool/pool.action'
import { useDispatch, useSelector } from 'react-redux'
import { decode } from 'js-base64'
import moment from 'moment'
import ModalLoading from '../../ProductPage/loadingModal'
import { useAccount, useNetwork } from 'wagmi'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowAltCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { platformSupportedChains } from '../../../config/config';
import { ethers } from 'ethers';
import Swal from 'sweetalert2';
import { TailSpin } from 'react-loader-spinner';

const projectId = '2IgAtCMut1uyYqbIB9ZF2bSC7Kr' // <---------- your Infura Project ID
const projectSecret = 'c04ed2d487eef3a347902ef7cbafa984' // <---------- your Infura Secret
const auth =
  'Basic ' + Buffer.from(projectId + ':' + projectSecret).toString('base64')

const client = create({
  host: 'ipfs.infura.io',
  port: 5001,
  protocol: 'https',
  headers: {
    authorization: auth
  }
})


function EditPool(props) {
  let { poolId } = useParams()
  poolId = decode(poolId)
  const dispatch = useDispatch()
  const { address } = useAccount()
  const pool = useSelector(state => state.pool?.pool)
  const [acc, setAcc] = useState('')
  const [loading, setLoading] = useState(false)
  const { chain, chains } = useNetwork();
  const [docLoading, setDocLoading] = useState(false);
  const [logoError, setLogoError] = useState('');



  // const [modalSuccess, setModalSuccess] = useState(false);
  // const [bidBalErr, setBidBalErr] = useState("");
  const [alert, setAlert] = useState('')
  var myRef = useRef([])

  const [poolMeta, setPoolMeta] = useState({
    title: '',
    description: '',
    logo: '',
    hash: '',
    website: '',
    telegram: '',
    discord: '',
    medium: '',
    twitter: ''
  })

  const [errors, setErrors] = useState({})

  useEffect(() => {
    if (address !== undefined) {
      setAcc(address)
    }

    if (address === 'undefined') {
      setAcc('Connect Wallet')
    }
  }, [address, acc])

  useEffect(() => {
    if (poolId) {
      dispatch(poolById(poolId))
    }
  }, [poolId, dispatch])

  useEffect(() => {
    if (pool) {
      setPoolMeta(pool)
    }
  }, [pool])

  const checkMimeType = event => {
    //getting file object
    let file = event.target.files;
    //define message container
    let err = '';
    // list allow mime type
    const types = ['image/png', 'image/jpg', 'image/jpeg', 'image/gif'];

    // compare file type find doesn't matach
    if (types.every(type => file[0]?.type !== type)) {
      // create error message and assign to container
      err = file[0].type + ' is not a supported format';
    }

    if (err !== '') {
      // if message not same old that mean has error
      event.target.value = null; // discard selected file
      console.log(err);
      return false;
    }
    return true;
  };

  const handleLogoChange = () => {
    setPoolMeta({ ...poolMeta, logo: '' });
    setLogoError('Please select logo');
  };

  const handleChange = async e => {
    // e.preventDefault()
    const { name, value } = e.target
    if (name === 'logo') {
      setDocLoading(true);
      const file = e.target.files[0];
      setLogoError('');
      if (checkMimeType(e)) {
        const logoUrl = await client.add(file);
        const url = `${logoUrl.path}`;
        setPoolMeta({ ...poolMeta, [name]: url });
        setTimeout(() => setDocLoading(false), 3000)
      } else {
        Swal.fire('Failed! Please select correct Image Format', 'Supported files ["png", "jpg", "jpeg", "gif"]', 'error');
      }
    } else {
      // handleValidation()
      setPoolMeta({ ...poolMeta, [name]: value })
    }
    // handleValidation()
  }

  //add validations to fieds to catch errors
  const handleValidation = async () => {
    const { title, website, telegram, discord, medium, twitter, logo } = poolMeta
    var newErrors = {}
    if (title === '') {
      newErrors.title = 'Please enter title value'
    }
    if(logo === '' || logo === undefined){
      newErrors.logo = 'Please select an Image'
    }

    const regex = new RegExp(
      'https?://(www.)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?'
    )
    if (website && !regex.test(website)) {
      newErrors.website = 'Enter valid website link'
    }
    if (telegram && !regex.test(telegram)) {
      newErrors.telegram = 'Enter valid telegram link'
    }

    if (twitter && !regex.test(twitter)) {
      newErrors.twitter = 'Enter valid twitter link'
    }

    if (medium && !regex.test(medium)) {
      newErrors.medium = 'Enter valid medium link'
    }

    if (discord && !regex.test(discord)) {
      newErrors.discord = 'Enter valid discord link'
    }

    if (Object.keys(newErrors)?.length > 0) {
      setErrors(newErrors)
      // setModalShow(false);
      return false
    } else {
      // setBtnActive(true)
      setErrors({})
      // setApPool(false);
      return true
    }
  }

  const uploadToIPFS = async data => {
    const res = await client.add(JSON.stringify(data))
    return res.path
  }

  const FetchProvider = async (tokenAdd, Abi) => {
    const provider = new ethers.providers.Web3Provider(window.ethereum || process.env.REACT_APP_TESTNET_RPC_URL);
    const signer = provider.getSigner();
    if (ethers.utils.isAddress(tokenAdd)) {
      var address = tokenAdd;
      var contract = new ethers.Contract(address, Abi, signer);
      return contract;
    }
  };
  const contractHandler = (Abi, tokenAdd) => {
    const web3 = new Web3(window.ethereum);
    const contract = new web3.eth.Contract(Abi, tokenAdd);
    return contract;
  };

  const fetchBal = async addresss => {
    let TokenData={}
    const contract = await FetchProvider(addresss, TokenAbi);
    const Decimals = parseInt(await contract.decimals());
    const symbol = await contract.symbol();
    const name = await contract.name();
    const count = parseInt(await contract.balanceOf(address?.toLocaleLowerCase()));
    const balInDecimals = (count / Math.pow(10, Decimals)).toFixed(3);
    // setPoolBalance(balInDecimals);
    TokenData.decimals = Decimals;
    TokenData.symbol = symbol;
    TokenData.name = name;
    TokenData.balInDecimals = balInDecimals;
    return TokenData
  };

  const networkCheck = ()=>{
    if(pool?.network === platformSupportedChains[chain.id]?.name) 
    return true;
  }
  

  const updatePool = async () => {
    const validate = await handleValidation()
    if (validate ) {
      if(networkCheck() ){
        setLoading(true)
        const chainContract = platformSupportedChains[chain.id];
        const mutoPool = contractHandler(ContractAbi, chainContract.poolContract);
        let tokenAddr = pool?.poolingToken
        const {balInDecimals} = await fetchBal(tokenAddr)
        try {
          // const balanceCheck = await tokenContract.methods.balanceOf(address?.toLocaleLowerCase()).call()
          if (balInDecimals> 0) {
            // setApTok1(true);
            // const { hash, remove, ...rest } = poolMeta;
            let pathHash = await uploadToIPFS(poolMeta)
            let res = await mutoPool.methods
              .updatePoolUser(poolId, pathHash)
              .send({ from: window.ethereum.selectedAddress })
            if (res.blockHash) {
              // setApTok2(true);
              // setModalSuccess(true);
              let resdt = dispatch(updatePoolUser(poolId, poolMeta))
              if(resdt){
                setLoading(false);
                Swal.fire({
                  icon: 'success',
                  text: 'Pool is updated',
                });
              }
            }
          } else {
            setLoading(false);
            Swal.fire({
            icon: 'error',
            text: 'You do not have enough balance',
          });
          }
        } catch (error) {
          setLoading(false)
          console.log(error)
          Swal.fire({
            icon: 'error',
            text: 'Transaction has been reverted',
          });
        }

      } else {
        Swal.fire({
          title: 'wrong network',
          icon: 'error',
          text: 'Pool is only updated on which network is created',
        });
      }
     
    } else {
      Swal.fire({
        icon: 'error',
        text: 'Please fill the form complete and correctly',
      });
      // setModalShow(false);
    }
  }

  const handleImgError = ev => { }
  // const handleBack = () => {
  //   props.handleCloseAddPool();
  // };

  const AlertNotify = (message, time) => {
    setAlert(message)

    setTimeout(() => {
      setAlert('')
    }, time)
  }

  return (
    <>
      <div className='mutopad-w-100'>
        <div className='content'>
          <Card className='pt-lg-4 pt-3 h-auto' id='style-6'>
            <Card.Header className="titles-sett">
              <h2 className="text-shadow">Edit Pool</h2>
              <div className="sm-right-btn">
                <Link className="d-inline light-blue-btn text-capitalize" to="/admin/managepools">
                  <FontAwesomeIcon className="add-icon" icon={faArrowAltCircleLeft} /> Back to Pools
                </Link>
              </div>
            </Card.Header>
            <Card.Body>
              <div className='container'>
                <div className='heading'>
                  <h3 className='text-white  mt-4'>Information</h3>
                </div>
                <hr className='text-white'></hr>

                <Form
                  className='create-pool text-white'
                  onKeyUp={handleValidation}
                >
                  <Row className='align-items-center'>
                    <div className='col-md-6 mb-md-0 mb-3'>
                      <Form.Group
                        className='mb-md-4 mb-4 input_error'
                        controlId='title'
                      >
                        <Info
                          name={'Title'}
                          desc={'Title Should be in String and max 50 letter'}
                        />
                        <Form.Control
                          type='text'
                          ref={myRef}
                          placeholder='Enter Title of Token'
                          value={poolMeta.title}
                          name='title'
                          onChange={handleChange}
                          isInvalid={!!errors.title}
                        />
                        <ErrorLabels props={errors.title} />
                      </Form.Group>
                    </div>
                    <div className='col-md-6 mb-3'>
                      <div className='cross-icon-wrapper'>
                        {poolMeta.logo !== '' ? (
                            <span className='cross-icon' onClick={handleLogoChange}>
                              x
                            </span>
                          ) : (
                            <></>
                          )}
                           <div className='img-cover-box'>
                          {
                            poolMeta?.logo === '' || poolMeta?.logo === undefined ?
                            <>
                              <Form.Label htmlFor='fileUpload'>
                                <p className='logo_text mb-0 w-75'>
                                  <figure className='mx-auto upload-img'>
                                    <img src={uploadImg} className='img-fluid' alt='Upload'></img>
                                  </figure>
                                  <span>
                                    Drop your image here, or <span className='text-shadow fw-bold'>Browse</span>
                                  </span>
                                </p>
                              </Form.Label>
                              <Form.Control className='upload_logo h-100 w-100 p-0' name='logo' accept='image/*' placeholder='Upload Logo' type='file' onChange={handleChange} />
                              <span className='text-danger'>Please select an Image</span>
                            </>
                            :
                            <>
                              {
                                poolMeta?.logo !== undefined && poolMeta?.logo !== '' && docLoading === false ?
                                <>
                                  <Form.Control className='upload_logo h-100 w-100 p-0' name='logo' placeholder='Upload Logo' type='file' onChange={()=> {handleChange(), setDocLoading(true)} } />
                                  <img src={`https://ipfs.io/ipfs/${poolMeta.logo}`} alt='logo' className='img-fluid' width='100' height='100' onError={handleImgError}></img>
                                </>
                                :
                                <> 
                                  <div className='text-center faq-loader'>
                                    <TailSpin height='30' width='30' color='#46bdf4' ariaLabel='loading' />
                                  </div>
                                </>
                              }
                            </>
                          }
                        </div>
                      </div>
                    </div>

                    <div className='col-md-12 mb-md-0 mb-3'>
                      <Form.Group
                        className='mb-md-5 mb-4 cms-pages-style'
                        controlId='description'
                      >
                        <Info
                          name={'Description'}
                          desc={'Description should be general and brief'}
                        />
                        {/* <Form.Control
                          as='textarea'
                          rows={3}
                          name='description'
                          value={poolMeta.description}
                          placeholder='Enter Description'
                          onChange={handleChange}
                        /> */}
                         <CKEditor editor={ClassicEditor} data={poolMeta.description == null ? '' : poolMeta.description} onChange={(event, editor) => {
                          const data = editor.getData();
                          setPoolMeta({ ...poolMeta, description: data })
                        }} />
                      </Form.Group>
                    </div>
                  </Row>

                  {/*------------- Socials----------------- */}
                  <div className='heading'>
                    <h3 className='text-white  mt-4'>Other Details</h3>
                  </div>
                  <hr className='text-white mb-5'></hr>

                  <Row>
                    <div className='col-md-6 mb-md-0 mb-3'>
                      <Form.Group
                        className='mb-md-5 mb-4'
                        controlId='twitter'
                      >
                        <Form.Label>Twitter</Form.Label>
                        <Info desc={'format: https://xyz.com'} />
                        <Form.Control
                          type='url'
                          value={poolMeta.twitter}
                          onChange={handleChange}
                          name='twitter'
                          placeholder='Twitter Link'
                          isInvalid={!!errors.twitter}
                        />

                        <ErrorLabels props={errors.twitter} />
                      </Form.Group>

                      <Form.Group className='mb-md-5 mb-4' controlId='medium'>
                        <Form.Label>Medium</Form.Label>
                        <Info desc={'format: https://xyz.com'} />

                        <Form.Control
                          type='url'
                          value={poolMeta.medium}
                          onChange={handleChange}
                          name='medium'
                          placeholder='Medium Link'
                          isInvalid={!!errors.medium}
                        />

                        <ErrorLabels props={errors.medium} />
                      </Form.Group>
                      <Form.Group
                        className='mb-md-5 mb-4'
                        controlId='formBasicEmail'
                      >
                        <Form.Label>Website</Form.Label>
                        <Info desc={'format: https://xyz.com'} />

                        <Form.Control
                          type='url'
                          value={poolMeta.website}
                          onChange={handleChange}
                          name='website'
                          placeholder='Website Link'
                          isInvalid={!!errors.website}
                        />

                        <ErrorLabels props={errors.website} />
                      </Form.Group>
                    </div>
                    <div className='col-md-6 mb-md-0 mb-3'>
                      <Form.Group
                        className='mb-md-5 mb-4'
                        controlId='formBasicEmail'
                      >
                        <Form.Label>Discord</Form.Label>
                        <Info desc={'format: https://xyz.com'} />

                        <Form.Control
                          type='url'
                          value={poolMeta.discord}
                          onChange={handleChange}
                          name='discord'
                          placeholder='Discord Link'
                          isInvalid={!!errors.discord}
                        />

                        <ErrorLabels props={errors.discord} />
                      </Form.Group>

                      <Form.Group
                        className='mb-md-5 mb-4'
                        controlId='formBasicEmail'
                      >
                        <Form.Label>Telegram</Form.Label>
                        <Info desc={'format: https://xyz.com'} />

                        <Form.Control
                          type='url'
                          value={poolMeta.telegram}
                          onChange={handleChange}
                          name='telegram'
                          placeholder='Telegram Link'
                          isInvalid={!!errors.telegram}
                        />

                        <ErrorLabels props={errors.telegram} />
                      </Form.Group>
                    </div>
                  </Row>
                  {/*------------ tokennomics---------- */}
                  <div className='btn-wrapper border-0'>
                    <Button
                      className='light-blue-btn w-10'
                      onClick={() =>{
                        pool?.poolOwner === address?.toLocaleLowerCase() ? updatePool() :
                        Swal.fire({
                          title:'could not edit',
                          icon: 'error',
                          text: 'You are not a pool owner',
                        })
                      }}
                    >
                      Update Pool
                    </Button>
                  </div>
                </Form>
              </div>
              {alert !== '' ? <Alerts message={alert} show={true} /> : <></>}
            </Card.Body>
          </Card>
          {loading ? <ModalLoading /> : <></>}
        </div>
      </div>
    </>
  )
}

export default EditPool
