/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleLeft, faAngleDoubleRight, faChartPie, faPoll } from '@fortawesome/free-solid-svg-icons'
import { faPrint } from '@fortawesome/free-solid-svg-icons'
import { faWrench } from '@fortawesome/free-solid-svg-icons'
import { faToolbox } from '@fortawesome/free-solid-svg-icons'
import { useLocation } from 'react-router-dom'
import { faBellSlash, faQuestionCircle } from '@fortawesome/free-regular-svg-icons'
import { Button, Dropdown, Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';


function Sidenav({ ownerFlag }) {

  const location = useLocation();
  const navigate = useNavigate()
  const pathname = location.pathname;
  const pools = useSelector(state => state?.pool?.poolsList);

  //**  state Variables **//
  const [isOpen, setIsOpen] = useState(false);
  //** all logical functions **//
  const onClickSidebar = () => {
    setIsOpen(!isOpen)
  }

  //** useEffects * dependencies  **//



  return (
    <>
      {/* <div className={isOpen ? "sidebar sidbar-hide" : "sidebar close"}> */}
        <div id='sidebar' className={isOpen ? "sidebar sidebar-hide" : "sidebar close"}>
          <Button
            className="toggle-btn sidebar-btn-close" onClick={() => onClickSidebar()}>
            {isOpen ? <FontAwesomeIcon icon={faAngleDoubleLeft} /> : <FontAwesomeIcon icon={faAngleDoubleRight} />}
          </Button>
          <strong className='logo w-100 d-flex justify-content-center'>
          </strong>
          {ownerFlag === true ? (
            <>
              <ul className='main-nav list-unstyled' id="style-4">
                {/* <li style={{cursor:"pointer"}} onClick={()=>setModalShow(true)} >
                  <span className='admin-show'>
                    <FontAwesomeIcon className='me-2' icon={faBellSlash} />
                  </span>
                  <span className='admin-show' >Notifications</span><span>  ({eventData?.length})</span>
                </li> */}
                <li className={pathname === "/admin/dashboard" ? "active" : ""}>
                  <span className='admin-show'>
                    <FontAwesomeIcon className='me-2' icon={faChartPie} />
                  </span>
                  <Link className='admin-none' to='dashboard'>
                    <span>
                      <FontAwesomeIcon className='me-2' icon={faChartPie} />
                    </span>
                  </Link>
                  <Link className='admin-show active' to='dashboard'>Dashboard</Link>
                </li>
                <Dropdown className='dashboard-side-drop'>
                  <Dropdown.Toggle id="dropdown-basic" className='d-flex align-items-center'>
                    <div className='pools-dd d-flex align-items-center'>
                    <span className='admin-show'>
                      <FontAwesomeIcon className='me-2' icon={faToolbox} />
                    </span>
                      Pools
                    </div>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <li className={pathname === "/admin/managepools" ? "active" : ""}>
                      <span className='admin-show'>
                        <FontAwesomeIcon className='me-2' icon={faToolbox} />
                      </span>
                      <Link to='managepools' className='admin-none'>
                        <span>
                          <FontAwesomeIcon className='me-2' icon={faToolbox} />
                        </span>
                      </Link>
                      <Link to='managepools' className='text-white admin-show active'>
                        Manage Pools
                      </Link>
                    </li>
                    <li className={pathname === "/admin/manage-ended-pools" ? "active" : ""}>
                      <span className='admin-show'>
                        <FontAwesomeIcon className='me-2' icon={faToolbox} />
                      </span>
                      <Link to='manage-ended-pools' className='admin-none'>
                        <span>
                          <FontAwesomeIcon className='me-2' icon={faToolbox} />
                        </span>
                      </Link>
                      <Link to='manage-ended-pools' className='text-white admin-show active'>
                        Manage Ended Pools
                      </Link>
                    </li>

                  </Dropdown.Menu>
                </Dropdown>
                <li className={pathname === "/admin/faqs" ? "active" : ""}>
                  <span className='admin-show'>
                    <FontAwesomeIcon className='me-2' icon={faQuestionCircle} />
                  </span>
                  <Link to='faqs' className='admin-none'>
                    <span>
                      <FontAwesomeIcon className='me-2' icon={faQuestionCircle} />
                    </span>
                  </Link>
                  <Link to='faqs' className='text-white admin-show'>
                    Manage Faqs
                  </Link>
                </li>
                <li className={pathname === "/admin/manage-cmspages" ? "active" : ""}>
                  <span className='admin-show'>
                    <FontAwesomeIcon className='me-2' icon={faPrint} />
                  </span>
                  <Link to='manage-cmspages' className='admin-none'>
                    <span>
                      <FontAwesomeIcon className='me-2' icon={faPrint} />
                    </span>
                  </Link>
                  <Link to='manage-cmspages' className='text-white admin-show'>
                    CMS Pages
                  </Link>
                </li>

                <li className={pathname === "/admin/settings" ? "active" : ""}>
                  <span className='admin-show'>
                    <FontAwesomeIcon className='me-2' icon={faWrench} />
                  </span>
                  <Link to='settings' className='admin-none'>
                    <span>
                      <FontAwesomeIcon className='me-2' icon={faWrench} />
                    </span>
                  </Link>
                  <Link to='settings' className='text-white admin-show'>
                    Settings
                  </Link>
                </li>
              </ul>
            </>
          ) : (
            <>
              <ul className='main-nav list-unstyled' id="style-4">
                <li className={pathname === "/admin/dashboard" ? "active" : ""}>
                  <span className='admin-show'>
                    <FontAwesomeIcon className='me-2' icon={faChartPie} />
                  </span>
                  <Link className='admin-none' to='dashboard'>
                    <span>
                      <FontAwesomeIcon className='me-2' icon={faChartPie} />
                    </span>
                  </Link>
                  <Link className='admin-show active' to='dashboard'>Dashboard</Link>
                </li>
                <li className={pathname === "/admin/managepools" ? "active" : ""}>
                  <span className='admin-show'>
                    <FontAwesomeIcon className='me-2' icon={faToolbox} />
                  </span>
                  <Link to='managepools' className='admin-none'>
                    <span>
                      <FontAwesomeIcon className='me-2' icon={faToolbox} />
                    </span>
                  </Link>
                  <Link to='managepools' className='text-white admin-show active'>
                    Manage Pools
                  </Link>
                </li>
              </ul>
            </>
          )}
        </div>
        
      {/* </div> */}
    </>
  )
}

export default Sidenav
