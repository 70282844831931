import {
  CREATE_POOL,
  POOLS_LISTING,
  ALL_POOLS_LISTING,
  POOL_BY_ID,
  UPDATE_POOL,
  DELETE_POOL,
  ERROR_STATE,
  PLACE_POOL_ORDER,
  POOL_ORDERS,
  SETTLE_POOL_CREATE,
  CREATE_CLAIM_ORDER,
  UPDATE_POOL_STATUS,
  UPDATE_STATE,
  ADD_SCAM_POOL,
  EDIT_SCAM_POOL,
  CANCEL_POOL,
  UPDATE_FEATURE_STATUS,
  ADD_TO_WHITELIST,
  GET_WHITELIST_ADDRESSES
} from './pool.types';
// import { toast } from 'react-toastify';
import { apiHelper } from '../apiHelper';
// import { queueStartElement } from '../../utils/globalHelpers';

export const createPool = (poolData) => async (dispatch) => {
  try {
    let res = await apiHelper('post', `/v1/front/pools/create`, poolData);
    if (res?.data) {
      let { data } = res;
      // toast.success(res.data.message)
      dispatch({
        type: CREATE_POOL,
        payload: data
      });
    } else {
      dispatch({
        type: ERROR_STATE
      });
    }
  } catch (error) {
    console.log(error.res);
  }
};

export const poolsListing = (id, qs = null) => async (dispatch) => {
  try {
    let url = `/v1/admin/pools/list/${id}`;
    if (qs) {
      url += `?${qs}`;
    }
    let res = await apiHelper('get', url);
    if (res?.data) {
      let { data } = res;
      dispatch({
        type: POOLS_LISTING,
        payload: data
      });
    } else {
      dispatch({
        type: ERROR_STATE
      });
    }
  } catch (error) {
    console.log(error);
  }
};

export const poolById = (poolId) => async (dispatch) => {
  try {
    let res = await apiHelper('get', `/v1/front/pools/get/${poolId}`);
    if (res?.data) {
      let { data } = res;
      dispatch({
        type: POOL_BY_ID,
        payload: data
      });
    } else {
      dispatch({
        type: ERROR_STATE
      });
    }
  } catch (error) {
    console.log(error);
  }
};

export const updatePoolUser = (poolId, data) => async (dispatch) => {
  try {
    let res = await apiHelper('put', `/v1/front/pools/edit/${poolId}`, data);
    if (res?.data) {
      let { data } = res;
      // toast.success(res.data.message)
      dispatch({
        type: UPDATE_POOL,
        payload: data
      });
    } else {
      dispatch({
        type: ERROR_STATE
      });
    }
  } catch (error) {
    console.log(error.res.message);
  }
};

export const placePoolOrder = (data) => async (dispatch) => {
  try {
    let res = await apiHelper('post', `/v1/front/order/create`, data);
    if (res?.data) {
      let { data } = res;
      // toast.success(res.data.message)
      dispatch({
        type: PLACE_POOL_ORDER,
        payload: data
      });
    } else {
      dispatch({
        type: ERROR_STATE
      });
    }
  } catch (error) {
    console.log(error.res);
  }
};

export const ordersListing = (orderId) => async (dispatch) => {
  try {
    let res = await apiHelper('get', `/v1/front/order/get/${orderId}`);
    if (res?.data) {
      let { data } = res;
      dispatch({
        type: POOL_ORDERS,
        payload: data
      });
    } else {
      dispatch({
        type: ERROR_STATE
      });
    }
  } catch (error) {
    console.log(error);
  }
};

export const settelPoolCreate = (data) => async (dispatch) => {
  try {
    let res = await apiHelper('post', `/v1/front/settlepool/create`, data);
    if (res?.data) {
      let { data } = res;
      // toast.success(res.data.message)
      dispatch({
        type: SETTLE_POOL_CREATE,
        payload: data
      });
    } else {
      dispatch({
        type: ERROR_STATE
      });
    }
  } catch (error) {
  }
};

export const autoSettelPool = (data) => async (dispatch) => {
  try {
    let res = await apiHelper('post', `/v1/front/settlepool/create`, data);
    if (res?.data) {
      let { data } = res;
      // toast.success(res.data.message)
      dispatch({
        type: SETTLE_POOL_CREATE,
        payload: data
      });
    } else {
      dispatch({
        type: ERROR_STATE
      });
    }
  } catch (error) {
  }
};


export const createClaimedOrder = (data) => async (dispatch) => {
  try {
    let res = await apiHelper('post', `/v1/front/claim/create`, data);
    if (res?.data) {
      let { data } = res;
      // toast.success(res.data.message)
      dispatch({
        type: CREATE_CLAIM_ORDER,
        payload: data
      });
    } else {
      dispatch({
        type: ERROR_STATE
      });
    }
  } catch (error) {
  }

};

export const updateStatus = (poolId, data) => async (dispatch) => {
  try {
    let res = await apiHelper('patch', `/v1/admin/pools/updateStatus/${poolId}`, data);
    if (res?.data) {
      let { data } = res;
      // toast.success(res.data.message)
      dispatch({
        type: UPDATE_POOL_STATUS,
        payload: data
      });
    } else {
      dispatch({
        type: ERROR_STATE
      });
    }
  } catch (error) {
    console.log(error.res.message);
  }
};

export const markScamPool = (data) => async (dispatch) => {
  try {
    let res = await apiHelper('post', `/v1/front/scam/add`, data);
    if (res?.data) {
      let { data } = res;
      // toast.success(res.data.message)
      dispatch({
        type: ADD_SCAM_POOL,
        payload: data
      });
    } else {
      dispatch({
        type: ERROR_STATE
      });
    }
  } catch (error) {
  }
};

export const unMarkScamPool = (data) => async (dispatch) => {
  try {
    let res = await apiHelper('put', `/v1/front/scam/edit`, data);
    if (res?.data) {
      let { data } = res;
      // toast.success(res.data.message)
      dispatch({
        type: EDIT_SCAM_POOL,
        payload: data
      });
    } else {
      dispatch({
        type: ERROR_STATE
      });
    }
  } catch (error) {
  }
};

export const updateState = () => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_STATE
    });
  } catch (error) {
    console.log(error.res.message);
  }
}

export const allPoolsListing = (id, qs=null) => async (dispatch) => {
  try {
    id = id || '';
    let url = `/v1/front/pools/list/${id}`;
    if (qs) {
      url += `?${qs}`;
    }
    let res = await apiHelper('get', url);
    if (res?.data) {
      let { data } = res;
      dispatch({
        type: ALL_POOLS_LISTING,
        payload: data
      });
    } else {
      dispatch({
        type: ERROR_STATE
      });
    }
  } catch (error) {
    console.log(error);
  }
};

export const updateFeatureStatus = (poolId, data) => async (dispatch) => {
  try {
    let res = await apiHelper('patch', `/v1/admin/pools/updateFeatureStatus/${poolId}`, data);
    if (res?.data) {
      let { data } = res;
      // toast.success(res.data.message)
      dispatch({
        type: UPDATE_FEATURE_STATUS,
        payload: data
      });
    } else {
      dispatch({
        type: ERROR_STATE
      });
    }
  } catch (error) {
  }
};

export const toDeletePool = (data) => async (dispatch) => {
  try {
    let res = await apiHelper('put', `/v1/front/pools/softdelete`, data);
    if (res?.data) {
      let { data } = res;
      // toast.success(res.data.message)
      dispatch({
        type: DELETE_POOL,
        payload: data
      });
    } else {
      dispatch({
        type: ERROR_STATE
      });
    }
  } catch (error) {
  }
};

export const toCancelPool = (data) => async (dispatch) => {
  try {
    let res = await apiHelper('put', `/v1/front/pools/cancelpool`, data);
    if (res?.data) {
      let { data } = res;
      // toast.success(res.data.message)
      dispatch({
        type: CANCEL_POOL,
        payload: data
      });
    } else {
      dispatch({
        type: ERROR_STATE
      });
    }
  } catch (error) {
  }
};

export const addToWhiteList = (data) => async (dispatch) => {
  try {
    let res = await apiHelper('post', `/v1/front/whitelist/add`, data);
    if (res?.data) {
      let { data } = res;
      // toast.success(res.data.message)
      dispatch({
        type: ADD_TO_WHITELIST,
        payload: data
      });
    } else {
      dispatch({
        type: ERROR_STATE
      });
    }
  } catch (error) {
  }
};

export const getWhiteListAddresses = (id) => async (dispatch) => {
  try {
    let res = await apiHelper('get', `/v1/front/whitelist/get/${id}`, '');
    if (res?.data) {
      let { data } = res;
      // toast.success(res.data.message)
      dispatch({
        type: GET_WHITELIST_ADDRESSES,
        payload: data
      });
    } else {
      dispatch({
        type: ERROR_STATE
      });
    }
  } catch (error) {
  }
};
