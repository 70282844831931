export const CREATE_POOL = 'CREATE_POOL';
export const POOLS_LISTING = 'POOLS_LISTING';
export const ALL_POOLS_LISTING = 'ALL_POOLS_LISTING';
export const POOL_BY_ID = 'POOL_BY_ID';
export const UPDATE_POOL = 'UPDATE_POOL';
export const DELETE_POOL = 'DELETE_POOL';
export const ERROR_STATE = 'ERROR_STATE';
export const PLACE_POOL_ORDER = 'PLACE_POOL_ORDER';
export const POOL_ORDERS = 'POOL_ORDERS';
export const SETTLE_POOL_CREATE = 'SETTLE_POOL_CREATE';
export const CREATE_CLAIM_ORDER = 'CREATE_CLAIM_ORDER';
export const UPDATE_POOL_STATUS = 'UPDATE_POOL_STATUS';
export const UPDATE_STATE = 'UPDATE_STATE';
export const ADD_SCAM_POOL = 'ADD_SCAM_POOL';
export const EDIT_SCAM_POOL = 'EDIT_SCAM_POOL';
export const UPDATE_FEATURE_STATUS = 'UPDATE_FEATURE_STATUS';
export const CANCEL_POOL = 'CANCEL_POOL';
export const ADD_TO_WHITELIST = 'ADD_TO_WHITELIST';
export const GET_WHITELIST_ADDRESSES = 'GET_WHITELIST_ADDRESSES';