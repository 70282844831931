import React from 'react'
import { BarChart, CartesianGrid, XAxis, YAxis, Bar, Tooltip, Legend } from 'recharts'

const Barchart = ({ startedPools, upcomingPools, endedPools }) => {

  const totalPools = startedPools?.length + upcomingPools?.length + endedPools?.length
  const data = [
    {
      "totalPools": `Total Pools: ${totalPools}`,
      "Live": `${startedPools?.length}`,
      "Upcoming": `${upcomingPools?.length}`,
      "Ended": `${endedPools?.length}`
    }
  ]

  return (
    <div className='dashboard-chart-wrapper'>
      <BarChart width={730} height={250} data={data}>
        <CartesianGrid strokeDasharray="" />
        <XAxis dataKey="totalPools" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="Live" fill="#4287f5" />
        <Bar dataKey="Upcoming" fill="#42f56f" />
        <Bar dataKey="Ended" fill=" #eded18" />
      </BarChart>
    </div>)
}

export default Barchart;