import React, { useState } from 'react';
import Icon2 from '../../assets/images/icon-2.png';
import Icon1 from '../../assets/images/icon-1.png';
import Twitter from '../../assets/images/twiiter.png';
import Icon4 from '../../assets/images/icon-4.png';
import Icon5 from '../../assets/images/horn.svg';
import bnbIcon from '../../assets/images/bnnicon.png';
import etherIcon from '../../assets/images/curr-img-one.png';

import { ProgressBar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { DefualtImageSetter } from '../../utils/globalHelpers';
import { TailSpin } from 'react-loader-spinner';
import moment from 'moment';
import { encode } from 'js-base64';

function IdoCards ({ pools, currentIdoLength }) {
  const socialLinks = [Icon1, Icon2, Twitter, Icon5, Icon4];
  const [loadMoreIdo, setLoadMoreIdo] = useState(6);
  let offsetInSeconds = new Date().getTimezoneOffset() * 60 *1;
  offsetInSeconds = offsetInSeconds > 0 ?  -offsetInSeconds : Math.abs(offsetInSeconds)
  const handleLogoScroll = () => {
    window.scroll(0, 0);
  };

  const htmlFormater = content => {
    return { __html: content };
  };

  return (
    <div className='container'>
      <div className='row'>
        {pools.length > 0 && pools.filter((data) => data.poolStatus === true) &&
          pools.slice(0, loadMoreIdo).map((item, index) => {
            return (
              <div className='col-lg-4 col-md-6 col-sm-12 col-12 mb-md-4 mb-3' key={index}>
                <Link to={`/${'pool'}/${encode(item.poolId)}`} className='card mb-3' onClick={handleLogoScroll}>
                  <div className='d-flex justify-content-between flex-column h-100'>
                    <div className='d-block'>
                      <div className='d-flex align-items-center mb-4' animation='wave'>
                        <div className='pools-card-images-info-wrapper pe-4'>
                          <div className='pools-card-images-info'>
                            <p className='card-title s-line first mb-1'>{item.title}</p>
                            <div className='d-flex flex-column'>
                              <p className='text-capitalize s-line second mb-1'>{item.ptSymbol} </p>
                            </div>
                          </div>
                        </div>
                        <figure className='m-0 game-img game-img-wrap'>
                          <img className='img-fluid profile' src={`https://ipfs.io/ipfs/${item.logo}`} alt='logo' onError={DefualtImageSetter} />
                          <div className='game-img-wrap-child-img'>
                            {item?.network === 'BSC Testnet' ? <img src={bnbIcon} alt='logo' onError={DefualtImageSetter} /> : <img src={etherIcon} alt='logo' onError={DefualtImageSetter} />}
                          </div>
                        </figure>
                      </div>
                      <div className='social-icon-bar p-0 mb-4'>
                        <ul>
                          {item?.telegram !== '' && (
                            <li>
                              <figure className='mb-0'>
                                <a href={item?.telegram ? item?.telegram : ''} target='_blank' rel='noopener noreferrer'>
                                  <img className='img-fluid' src={`${socialLinks[0]}`} alt='social-icon' />
                                </a>
                              </figure>
                            </li>
                          )}
                          {item?.medium !== '' && (
                            <li>
                              <figure className='mb-0'>
                                <a href={item?.medium ? item?.medium : ''} target='_blank' rel='noopener noreferrer'>
                                  <img className='img-fluid' src={`${socialLinks[1]}`} alt='social-icon' />
                                </a>
                              </figure>
                            </li>
                          )}
                          {item?.twitter !== '' && (
                            <li>
                              <figure className='mb-0'>
                                <a href={item?.twitter ? item?.twitter : ''} target='_blank' rel='noopener noreferrer'>
                                  <img className='img-fluid' src={`${socialLinks[2]}`} alt='social-icon' />
                                </a>
                              </figure>
                            </li>
                          )}
                          {item?.podcast !== '' && (
                            <li>
                              <figure className='mb-0'>
                                <a href={item?.podcast ? item?.podcast : ''} target='_blank' rel='noopener noreferrer'>
                                  <img className='img-fluid' src={`${socialLinks[3]}`} alt='social-icon' />
                                </a>
                              </figure>
                            </li>
                          )}
                          {item?.website !== '' && (
                            <li>
                              <figure className='mb-0'>
                                <a href={item?.website ? item?.website : ''} target='_blank' rel='noopener noreferrer'>
                                  <img className='img-fluid' src={`${socialLinks[4]}`} alt='social-icon' />
                                </a>
                              </figure>
                            </li>
                          )}
                        </ul>
                      </div>
                      <div className='badges ms-0 mb-4'>
                        {console.log(moment.unix((parseInt(item?.poolStartDate) + offsetInSeconds)).format(''))}
                        {(parseInt(item?.poolStartDate)) < moment(Date.now()).unix() && (parseInt(item?.poolEndDate)) > moment(Date.now()).unix() && item?.closeStatus !== true ? (
                          <span className='outerside live-badge'>Live</span>
                        ) : (
                          ''
                        )}
                        {item?.closeStatus !== true && (parseInt(item?.poolStartDate)) > moment(Date.now()).unix() ? (
                          <>
                            <span className='outerside soon' style={{ float: 'right' }}>
                              Upcoming
                            </span>
                          </>
                        ) : (
                          <></>
                        )}
                        {item?.closeStatus === true || (parseInt(item?.poolEndDate)) < moment(Date.now()).unix() ? (
                          <span className='outerside close-badge' style={{ float: 'right' }}>
                            Closed
                          </span>
                        ) : (
                          ''
                        )}
                         {item?.poolStatus === true ? (
                          <span className='outerside badge-light-green kyc' style={{ float: 'right' }}>
                            Kyc
                          </span>
                        ) : (
                          ''
                        )}
                      </div>
                      <div className='curr-info mb-4'>
                        <p>
                        <div dangerouslySetInnerHTML={htmlFormater(item?.description)} />
                        </p>
                      </div>
                      <div className='pools-sell-details-wrapper mb-4'>
                        <div className='pools-sell-details d-flex align-items-center justify-content-between'>
                          <h6>Token for Sale</h6>
                          <p>
                            {item?.poolSellAmount} {item?.ptSymbol}
                          </p>
                        </div>
                        <div className='pools-sell-details d-flex align-items-center justify-content-between'>
                          <h6>Total Raise</h6>
                          <p>
                            {item?.thresholdData[0]?.thresholdCounter ? item?.thresholdData[0]?.thresholdCounter : 0} {item?.bdSymbol}
                          </p>
                        </div>
                        <div>
                          {(parseInt(item.poolEndDate)) < moment(Date.now()).unix() ? (
                            <div className='pools-sell-details d-flex align-items-center justify-content-between'>
                              <h6>Ended</h6>
                              <p className='date-color'>{moment.unix(item.poolEndDate).utc().format('DD MMMM YYYY, HH:mm ')} UTC</p>{' '}
                            </div>
                          ) : (parseInt(item.poolEndDate)) > moment(Date.now()).unix() && parseInt(item.poolStartDate) < moment(Date.now()).unix() ? (
                            <div className='pools-sell-details d-flex align-items-center justify-content-between'>
                              <h6>Started</h6>
                              <p className='date-color'>{moment.unix(item.poolStartDate).utc().format('DD MMMM YYYY, HH:mm ')} UTC</p>{' '}
                            </div>
                          ) : (
                            <div className='pools-sell-details d-flex align-items-center justify-content-between'>
                              <h6>Starts</h6>
                              <p className='date-color'>{moment.unix(item.poolStartDate).utc().format('DD MMMM YYYY, HH:mm ')} UTC</p>{' '}
                            </div>
                          )}
                        </div>
                      </div>
                      {/* <div className='progress-bar'>
                        <div className='prog-text d-flex '>
                          <p className='mb-1'>
                          🏃 <> </> {item?.remainingTime.day} Days and {item?.remainingTime.hours} Hours
                          </p>
                        </div>
                      </div> */}
                      <div className='progress-bar'>
                        <div className='prog-text d-flex'>
                          <span style={{ color: 'white' }}>Progress</span>
                          <span style={{ color: 'white' }}>{item?.thresholdData?.length > 0 ? item?.thresholdData?.length : 0} Bids</span>
                        </div>
                        <div className='mb-1'>
                          <ProgressBar now={item?.thresholdData?.length > 0 ? parseInt(item?.thresholdData[0]?.thresholdPercentage) : 0} style={{ color: 'pink' }} />
                        </div>
                        <div className='prog-text d-flex '>
                          <span style={{ color: 'white' }}>{item?.thresholdData?.length > 0 ? parseInt(item?.thresholdData[0]?.thresholdPercentage) : 0}%</span>
                          <span style={{ color: 'white' }}>
                            {item?.thresholdData?.length > 0 ? item?.thresholdData[0]?.thresholdCounter : 0} <span>/</span> {item?.minimumFundingThreshold}
                          </span>
                        </div>
                      </div>
                      <div className='wifi text-white'>
                      <svg stroke='currentColor' fill='currentColor' strokeWidth='0' viewBox='0 0 640 512' height='1em' width='1em' xmlns='http://www.w3.org/2000/svg'>
                        <path d='M634.91 154.88C457.74-8.99 182.19-8.93 5.09 154.88c-6.66 6.16-6.79 16.59-.35 22.98l34.24 33.97c6.14 6.1 16.02 6.23 22.4.38 145.92-133.68 371.3-133.71 517.25 0 6.38 5.85 16.26 5.71 22.4-.38l34.24-33.97c6.43-6.39 6.3-16.82-.36-22.98zM320 352c-35.35 0-64 28.65-64 64s28.65 64 64 64 64-28.65 64-64-28.65-64-64-64zm202.67-83.59c-115.26-101.93-290.21-101.82-405.34 0-6.9 6.1-7.12 16.69-.57 23.15l34.44 33.99c6 5.92 15.66 6.32 22.05.8 83.95-72.57 209.74-72.41 293.49 0 6.39 5.52 16.05 5.13 22.05-.8l34.44-33.99c6.56-6.46 6.33-17.06-.56-23.15z'></path>
                      </svg>
                      <span>IDO and distribution on {item?.network === 'Goerli Testnet' ? 'Ethereum' : 'BSC'}</span>

                    </div>
                    </div>
                    <div>
                      <p className='fw-bold ido-bold'></p>
                      <div className='card-footer mt-0'>
                        <div className='card-footer-content'>
                          <ul>
                            <li>
                              <p className='text-capitalize fw-bold ido-bold'>price</p>
                              <div className='d-flex align-items-baseline'>
                                <strong className='d-block feature-price blue me-2'>
                                  {item.minimumBuyAmount} <> </>
                                  {item.ptSymbol}
                                </strong>
                                <span className='text-capitalize'>
                                  {' '}
                                  <p>
                                    = {item.minimumBiddingAmount} <> </> {item.bdSymbol}
                                  </p>
                                </span>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            );
          })}
        {currentIdoLength !== pools.length && (
          <div className='text-center d-flex justify-content-center mb-2'>
            <TailSpin height='50' width='50' color='#46bdf4' ariaLabel='loading' />
          </div>
        )}
        {currentIdoLength > loadMoreIdo && (
          <div className='d-flex justify-content-center align-items-center'>
            {' '}
            <button className='light-blue-btn' onClick={() => setLoadMoreIdo(loadMoreIdo + 6)}>
              Load More
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default IdoCards;
