import { FAQ_LISTING, ADD_FAQ, FAQ_BY_ID, UPDATE_FAQ, DELETE_FAQ, ERROR_STATE, UPDATE_STATE } from './faq.types';
// import { toast } from 'react-toastify';
import { apiHelper } from '../apiHelper';

export const faqListing = () => async (dispatch) => {
  try {
    let res = await apiHelper('get', `/v1/admin/faq/list`);
    if (res?.data) {
      let { data } = res;
      dispatch({
        type: FAQ_LISTING,
        payload: data
      });
    } else {
      dispatch({
        type: ERROR_STATE
      });
    }
    return res.data.success
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const addFAQ = (data) => async (dispatch) => {
  try {
    let res = await apiHelper('post', `/v1/admin/faq/create`, data);
    if (res?.data) {
      let { data } = res;
      // toast.success(res.data.message)
      dispatch({
        type: ADD_FAQ,
        payload: data
      });
    } else {
      dispatch({
        type: ERROR_STATE
      });
    }
  } catch (error) {
    console.log(error.res);
  }
};

export const faqById = (id) => async (dispatch) => {
  try {
    let res = await apiHelper('get', `/v1/admin/faq/get/${id}`);
    if (res?.data) {
      let { data } = res;
      dispatch({
        type: FAQ_BY_ID,
        payload: data
      });
    } else {
      dispatch({
        type: ERROR_STATE
      });
    }
  } catch (error) {
    console.log(error.res.message);
  }
};

export const updateFAQ = (data) => async (dispatch) => {
  try {
    let res = await apiHelper('put', `/v1/admin/faq/edit`, data);
    if (res?.data) {
      let { data } = res;
      // toast.success(res.data.message)
      dispatch({
        type: UPDATE_FAQ,
        payload: data
      });
    } else {
      dispatch({
        type: ERROR_STATE
      });
    }
  } catch (error) {
    // toast.danger(error.res.message);
  }
};

export const deleteFAQ = (id) => async (dispatch) => {
  try {
    let res = await apiHelper('delete', `/v1/admin/faq/delete/${id}`);
    if (res?.data) {
      let { data } = res;
      // toast.success(res.data.message)
      dispatch({
        type: DELETE_FAQ,
        payload: data
      });
    } else {
      dispatch({
        type: ERROR_STATE
      });
    }
  } catch (error) {
    // toast.danger(error.res.message)
  }
};

export const updateState = () => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_STATE
    });
  } catch (error) {
    console.log(error.res.message);
  }
}