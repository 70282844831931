import React, { useEffect, useState } from "react";
import Sidenav from "./SideNav/sidenav";
import Setting from "./Settings/setting";
import { Routes, Route } from "react-router-dom";
import BannerAdmin from "./Banner/BannerAdmin";
import ManagePools from "./ManagePools/ManagePools";
import CmsPage from "./CMSPAGES/cmspage";
import ManageFaq from "./ManageFaqs/managefaq";
import Createpool from "../CreatePool/createpool";
import EditPool from "./ManagePools/EditPool";
import CreateCMSPage from "./CMSPAGES/CreateCMSPage";
import ManageCMSPages from "./CMSPAGES/ManageCMSPages";
import EditCMSPage from "./CMSPAGES/EditCMSPage";
import { useAccount } from 'wagmi'
import ManageEndedPools from "./ManagePools/ManageEndedPools";

function AdminPanel() {

  const { address } = useAccount()
  const [ownerFlag, setOwnerFlag] = useState(false)
  const owner = process.env.REACT_APP_OWNER_ADDRESS

  useEffect(() => {
    if (address !== undefined && address === owner) {
      setOwnerFlag(true)
    } else {
      setOwnerFlag(false)
    }
  }, [address, owner, setOwnerFlag])

  return (
    <>
      <div>
        <div className="admin-side-wraper">
          <Sidenav ownerFlag={ownerFlag} />
          <Routes>
            {ownerFlag === true ? (
              <>
                {" "}
                <Route path="dashboard" element={<BannerAdmin ownerFlag={ownerFlag} />}></Route>
                <Route path="managepools" element={<ManagePools walletAddress={address} />}></Route>
                <Route path="manage-ended-pools" element={<ManageEndedPools walletAddress={address} />}></Route>
                <Route path="create-pool" exact element={<Createpool />} />
                <Route path="create-cmspage" exact element={<CreateCMSPage />} />
                <Route path="manage-cmspages" exact element={<ManageCMSPages />} />
                <Route path="edit-pool/:poolId" exact element={<EditPool />} />
                <Route path="edit-cmspage/:pageId" exact element={<EditCMSPage />} />
                <Route path="faqs" element={<ManageFaq />}></Route>
                <Route path="cmspages" element={<CmsPage />}></Route>
                <Route path="settings" element={<Setting />}></Route>
              </>
            ) : (
              <>
                <Route path="dashboard" element={<BannerAdmin />}></Route>
                <Route path="managepools" element={<ManagePools walletAddress={address} />}></Route>
                <Route path="create-pool" exact element={<Createpool />} />
                <Route path="edit-pool/:poolId" exact element={<EditPool />} />
              </>
            )}
          </Routes>
        </div>
      </div>
    </>
  );
}

export default AdminPanel;
