/* eslint-disable */
import React, { useState, useEffect } from "react";
import "./landing.css";
import moment from "moment";
import { Nav, Tab } from "react-bootstrap";
import IdoCards from "./IdoCards";
import LoaderCardSkeleton from "./loadercardskeleton";
import { allPoolsListing } from "../../redux/pool/pool.action";
import { useDispatch, useSelector } from "react-redux";
import LandingBoxes from "./landingboxes";
import LandingSlick from "./landingslick";
import { useLocation, useSearchParams } from "react-router-dom";
import { objectToQueryString } from '../../config/config';
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const MultiChainCards = () => {
  const location = useLocation()
  const searchQuery = new URLSearchParams(location.search);
  const dispatch = useDispatch()
  const ownerAddress = (process.env.REACT_APP_OWNER_ADDRESS).toLocaleLowerCase()
  const pools = useSelector((state) => state?.pool?.allPoolsListing)
  const { pathname, search } = location
  const [allPools, setAllPools] = useState([]);
  const [startedPools, setStartedPools] = useState([]);
  const [upcomingPools, setUpcomingPools] = useState([]);
  const [endedPools, setEndedPools] = useState([]);
  const [loader, setLoader] = useState(true);
  const [filters, setFilters] = useState({
    poolName: searchQuery.get('poolName'),
  });
  const [searchParams] = useSearchParams()

  const GetRemainDays = (arg) => {
    var unix = Math.round(+new Date() / 1000);
    var date1 = unix;
    var date2 = arg;
    // To calculate the time difference of two dates
    var Difference_In_Time = date2 - date1;
    var remainHour = {};
    var days = Math.floor(Difference_In_Time / 86400);
    var hours = Math.floor(days / 60);
    if (days < 0) {
      remainHour.day = 0;
      if (remainHour.hours <= 0) {
        remainHour.hours = 0;
      }
    } else {
      remainHour.day = days;
      remainHour.hours = hours;
    }
    return remainHour;
  };

  const separateData = () => {
    let all = []
    let started = []
    let upcoming = []
    let ended = []
    pools.forEach((el) => {
      if (el.poolStatus === true) {
        let dateRes = GetRemainDays(parseInt(el.poolEndDate))
        el = { ...el, remainingTime: dateRes }
        all.push(el)
        if (parseInt(el.poolStartDate) < moment(Date.now()).unix() && parseInt(el.poolEndDate) < moment(Date.now()).unix()) {
          el = { ...el, remainingTime: dateRes }
          ended.push(el)
        } else if (parseInt(el.poolStartDate) < moment(Date.now()).unix()) {
          el = { ...el, remainingTime: dateRes }
          started.push(el)
        } else {
          el = { ...el, remainingTime: dateRes }
          upcoming.push(el)
        }
      }
    })
    setAllPools(all)
    setStartedPools(started)
    setUpcomingPools(upcoming)
    setEndedPools(ended)
  }

  useEffect(() => {
    if (pools?.length > 0) {
      separateData();
    }
    // eslint-disable-next-line
  }, [pools]);

  useEffect(() => {
    if (ownerAddress) {
      dispatch(allPoolsListing(ownerAddress))
    }
    // eslint-disable-next-line
  }, [ownerAddress]);

  useEffect(() => {
    handleClickScroll()
    // eslint-disable-next-line
  }, [pathname, search]);


  const handleClickScroll = () => {
    var element = document.getElementById(searchParams.get('id'));
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: 'auto' });
    }
  };

  const handleSearch = e => {
    e.preventDefault();
    const { name } = e.target;
    const obj = { ...filters };
    if (name === 'poolName') {
      obj.poolName = e.target.value;
    }
    if (name === 'poolName') {
      setFilters(obj);
    }
  };

  useEffect(() => {
    searchDoc(1)
  }, [filters])

  const searchDoc = async page => {
    if (page === undefined || page === '') page = 1;
    const params = {};
    if (filters.poolName) params.poolName = filters.poolName;
    const qs = objectToQueryString({ ...params, page });
    dispatch(allPoolsListing(ownerAddress, qs))
  };

  return (
    <>
      <section className="tab-cards" id='pools'>
        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
          <div className="container">
            <div className="d-flex align-items-center mb-5 pools-tabs-wrapper">
              <Nav variant="pills">
                <Nav.Item>
                  <Nav.Link name="first" eventKey="first">
                    All Pools ({allPools?.length})
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link name="second" eventKey="second">
                    Upcoming ({upcomingPools?.length})
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link name="third" eventKey="third">
                    Live ({startedPools?.length})
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link name="forth" eventKey="forth">
                    Ended ({endedPools?.length})
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <div className="pools-search search-tabs">
                <div className="position-relative pools-search-wrapper">
                  <input
                    name='poolName'
                    type='text'
                    defaultValue={filters.poolName === null ? '' : filters?.poolName}
                    className='form-control'
                    placeholder='Search by pool name'
                    onChange={handleSearch}
                  />
                  <FontAwesomeIcon icon={faSearch} className='text-white' />
                </div>
              </div>
            </div>
            <Tab.Content>
              <>
                <Tab.Pane eventKey="first">

                  {loader && setTimeout(() => setLoader(false), 2000) ? (
                    <>
                      <LoaderCardSkeleton />
                      {/* <span className="text-center">No Pool Found</span> */}

                    </>
                  ) : allPools?.length > 0 ? (
                    <>
                      <IdoCards pools={allPools} currentIdoLength={allPools?.length} />
                      {/* {upcomingPools.length !== idoInfo.length ? (
                        <div className='text-center'>
                          <TailSpin
                            height='50'
                            width='50'
                            color='#46bdf4'
                            ariaLabel='loading'
                          />
                        </div>
                      ) : (
                        <div className="d-flex justify-content-center align-items-center">
                          {" "}
                          <button
                            className="light-blue-btn"
                            onClick={handleLoadMore}
                          >
                            Load More
                          </button>
                        </div>
                      )} */}
                    </>
                  ) : (
                    <div className="text-white d-flex justify-content-center align-items-center">
                      <h5>No Pools Found</h5>
                    </div>
                  )}
                </Tab.Pane>

                <Tab.Pane eventKey="second">
                  {loader && setTimeout(() => setLoader(false), 2000) ? (
                    <>
                      <LoaderCardSkeleton />
                    </>
                  ) : upcomingPools?.length > 0 ? (
                    <>
                      <IdoCards pools={upcomingPools} currentIdoLength={upcomingPools?.length} />
                      {/* {upcomingPools.length !== idoInfo.length ? (
                        <div className='text-center'>
                          <TailSpin
                            height='50'
                            width='50'
                            color='#46bdf4'
                            ariaLabel='loading'
                          />
                        </div>
                      ) : (
                        <div className="d-flex justify-content-center align-items-center">
                          {" "}
                          <button
                            className="light-blue-btn"
                            onClick={handleLoadMore}
                          >
                            Load More
                          </button>
                        </div>
                      )} */}
                    </>
                  ) : (
                    <div className="text-white d-flex justify-content-center align-items-center">
                      <h5> No Pools Found </h5>
                    </div>
                  )}
                </Tab.Pane>

                <Tab.Pane eventKey="third">
                  {loader && setTimeout(() => setLoader(false), 2000) ? (
                    <>
                      <LoaderCardSkeleton />
                    </>
                  ) : startedPools?.length > 0 ? (
                    <>
                      <IdoCards pools={startedPools} currentIdoLength={startedPools?.length} />

                      {/* {startedPools.length !== idoInfo.length ? (
                        <div className='text-center'>
                        <TailSpin
                            height='50'
                            width='50'
                            color='#46bdf4'
                            ariaLabel='loading'
                          />
                        </div>
                      ) : (
                        <div className="d-flex justify-content-center align-items-center">
                          {" "}
                          <button
                            className="light-blue-btn"
                            onClick={handleLoadMore}
                          >
                            Load More
                          </button>
                        </div>
                      )} */}
                    </>
                  ) : (
                    <div className="text-white d-flex justify-content-center align-items-center">
                      <h5>No Pools Found</h5>
                    </div>
                  )}
                </Tab.Pane>
                <Tab.Pane eventKey="forth">
                  {loader && setTimeout(() => setLoader(false), 2000) ? (
                    <>
                      <LoaderCardSkeleton />
                    </>
                  ) : endedPools?.length > 0 ? (
                    <>
                      <IdoCards pools={endedPools} currentIdoLength={endedPools?.length} />
                      {/* {endedPools.length !== idoInfo.length ? (
                        <div className='text-center'>
                        <TailSpin
                            height='50'
                            width='50'
                            color='#46bdf4'
                            ariaLabel='loading'
                          />
                        </div>
                      ) : (
                        <div className="d-flex justify-content-center align-items-center">
                          {" "}
                          <button
                            className="light-blue-btn"
                            onClick={handleLoadMore}
                          >
                            Load More
                          </button>
                        </div>
                      )} */}
                    </>
                  ) : (
                    <div className="text-white d-flex justify-content-center align-items-center">
                      <h5>No Pools Found</h5>
                    </div>
                  )}
                </Tab.Pane>
              </>
            </Tab.Content>
          </div>
        </Tab.Container>
      </section>
      <div id='trending'>
        <LandingBoxes />
      </div>
      <LandingSlick pools={pools} />
    </>
  );
};

export default MultiChainCards;
