/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import { Modal, Form, Button, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { TailSpin } from 'react-loader-spinner';
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import { poolsListing, updateState, updateFeatureStatus } from '../../../redux/pool/pool.action';
import moment from 'moment';
import ApproveReject from './ApproveReject';
import Web3 from 'web3';
import ContractAbi from '../../../utils/contractAbi.json';
import ModalLoading from '../../ProductPage/loadingModal';
import 'rc-pagination/assets/index.css';
import { useAccount, useNetwork } from 'wagmi';
import { encode } from 'js-base64';
import ReactPaginate from 'react-paginate';
import { platformSupportedChains } from '../../../config/config';
import FullPageLoader from "../../Loaders/fullpageloader";

function ManageEndedPools({ walletAddress }) {
  const contractHandler = (Abi, tokenAdd) => {
    const web3 = new Web3(window.ethereum);
    const contract = new web3.eth.Contract(Abi, tokenAdd);
    return contract;
  };

  const ownerAddress = process.env.REACT_APP_OWNER_ADDRESS;
  const { address } = useAccount();
  const [show, setShow] = useState(false);
  const [userLoginAcc, setUserLoginAcc] = useState('');
  const [currentPoolData, setCurrentPoolData] = useState('');
  const [scamShow, setScamShow] = useState(false);
  const [unScamShow, setUnScamShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [scamState, setScamState] = useState({ message: '' });
  const { chain } = useNetwork();
  const dispatch = useDispatch();
  const pools = useSelector(state => state?.pool?.poolsList);
  const featureSuccess = useSelector(state => state?.pool?.featureSuccess);
  const delAuth = useSelector(state => state?.pool?.delAuth);
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    const endedPools = pools?.filter(res => parseInt(res.poolEndDate) < moment(Date.now()).unix());
    setCurrentItems(endedPools?.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(endedPools?.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, pools]);

  const handlePageClick = event => {
    const newOffset = (event.selected * itemsPerPage) % pools?.length;
    setItemOffset(newOffset);
  };

  const deletePool = (e, poolId, _id) => {
    e.preventDefault();
    Swal.fire({
      title: 'Are you sure want to delete it?',
      text: 'Once You deleted it could be removed or disabled permanently',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      reverseButtons: true,
    }).then(async result => {
      if (result.isConfirmed) {
        setLoading(true);
        let chainContract = platformSupportedChains[chain.id];
        let mutoPool = contractHandler(ContractAbi, chainContract.poolContract);
        try {
          let res = await mutoPool.methods.deletPool(poolId.toString()).send({ from: window.ethereum.selectedAddress });
          if (res) {
            let payload = {
              id: _id,
              isDeleted: true,
            };
            let rest = await dispatch(toDeletePool(payload));
            setLoading(false);
            if (rest) {
              Swal.fire('Deleted!', 'Pool has been deleted.', 'success');
            }
          }
        } catch (e) {
          setLoading(false);
          Swal.fire('Failed! transaction has been Reverted', 'Delete Pool Action not Successful', 'error');
        }
      }
    });
  };

  const featureHandle = (status, poolId) => {
    Swal.fire({
      title: '',
      text: `${status == undefined || status === false ? 'Are you sure want to add it into featured pools?' : 'Are you sure want to remove it from featured pools'}`,
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: 'success',
      cancelButtonText: 'No',
      confirmButtonText: 'Yes',
      reverseButtons: true,
    }).then(result => {
      if (result.isConfirmed) {
        // deletePool()
        if (status === undefined || status === false) {
          let data = {
            featureStatus: true,
          };
          dispatch(updateFeatureStatus(poolId, data));
          Swal.fire('Added!', 'Added to featured pool successfully.', 'success');
        } else {
          let data = {
            featureStatus: false,
          };
          dispatch(updateFeatureStatus(poolId, data));
          Swal.fire('Removed!', 'Removed from featured pool successfully.', 'success');
        }
      }
    });
  };

  useEffect(() => {
    dispatch(poolsListing(userLoginAcc));
  }, [show, userLoginAcc, dispatch]);

  useEffect(() => {
    if (delAuth === true) dispatch(poolsListing(userLoginAcc));
    dispatch(updateState());
  }, [show, userLoginAcc, dispatch, delAuth]);

  useEffect(() => {
    if (address !== undefined) {
      setUserLoginAcc(address.toLocaleLowerCase());
    }
  }, [address, userLoginAcc]);

  useEffect(() => {
    if (featureSuccess) {
      dispatch(poolsListing(userLoginAcc));
      dispatch(updateState());
    }
  }, [featureSuccess, dispatch, userLoginAcc]);

  const handleModal = () => {
    setShow(false);
  };

  return (
    <div className='mutopad-w-100'>
      <div className='content'>
        <div className='manage-pools-pg'>
          <Card className='pt-lg-4 pt-3 h-auto manage-card-table' id='style-6'>
            <Card.Header className='titles-sett'>
              <h2 className='text-shadow'>Manage Ended Pools</h2>
            </Card.Header>
            <Card.Body>
              {loader && setTimeout(() => setLoader(false), 2000) ? (
                <div className='text-center faq-loader'>
                  <FullPageLoader/>
                </div>
              ) : (
                <>
                  <div className='table-responsive manage-pools'>
                    <Table>
                      <thead>
                        <tr className='text-center'>
                          <th>Pool</th>
                          <th>Name</th>
                          <th>Tokenomics</th>
                          <th>Ended</th>
                          <th>Featured Status</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentItems && currentItems?.length > 0 ? (
                          currentItems?.map((item, index) => {
                            return (
                              <tr key={index} className='text-center'>
                                <td align='left'>
                                {item?.isDeleted === true ? (
                                  <>
                                    <Link
                                      style={{ color: 'white' }}
                                      onClick={() => {
                                        Swal.fire('Failed! Current Pool Has been Deleted/Disabled', 'Please Contact With Admin:)', 'error');
                                      }}
                                    >
                                      <FontAwesomeIcon className='del-icon' icon={faExternalLinkAlt} />{' '}
                                      <small>
                                        0x...
                                        {item.transactionHash?.substring(39, 42)}
                                      </small>
                                    </Link>
                                  </>
                                ) : (
                                  <>
                                    <Link to={`/pool/${encode(item.poolId)}`} style={{ color: 'white' }}>
                                      <FontAwesomeIcon className='del-icon' icon={faExternalLinkAlt} />{' '}
                                      <small>
                                        0x...
                                        {item.transactionHash?.substring(39, 42)}
                                      </small>
                                    </Link>
                                  </>
                                )}
                                <br />
                                <small className='align-left'>{item.network}</small>
                              </td>
                              <td>
                                <span style={{ fontSize: '16px' }}>{item.title}</span>
                                <br />
                                <span>
                                  <small className='tbl_pool_name'>by: {item.name}</small>
                                </span>
                              </td>
                              <td>
                                <small className='tbl_pool_tokenomics'>
                                  Minimum Bidding Amount: {item.minimumBiddingAmount} {item.bdSymbol}
                                </small>
                                <small className='tbl_pool_tokenomics'>
                                  Minimum Buy Amount: {item.minimumBuyAmount} {item.ptSymbol}
                                </small>
                                <small className='tbl_pool_tokenomics'>
                                  Threshold: {item.minimumFundingThreshold} {item.bdSymbol}
                                </small>
                              </td>
                              <td>
                                <span className='tbl_pool_time'>
                                  {moment.unix(item.poolStartDate).format('DD MMMM YYYY, HH:mm a')} UTC
                                  {/* {item.poolEndDate} */}
                                </span>
                              </td>
                                <td>
                                  <div className='d-flex align-items-center'>
                                    <div className='badges d-flex justify-content-between' style={{ margin: '0' }}>
                                      <div>
                                        {item?.featureStatus === true ? (
                                          <span className='outerside level'> Featured</span>
                                        ) : item?.featureStatus === false ? (
                                          <span className='outerside level'> Not Featured</span>
                                        ) : (
                                          <>
                                            <span className='outerside kyc'>Pending</span>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  {walletAddress === ownerAddress ? (
                                    <>
                                      <Dropdown size='sm' className='d-inline-block manage-pools-dd'>
                                        <Dropdown.Toggle size='sm' variant='default' id='dropdown-basic' className='text-light'>
                                          <FontAwesomeIcon className='del-icon' icon={faBars} />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu variant='dark' size='sm'>
                                          {/* <Dropdown.Item disabled={item?.isDeleted === true ? true : false} onClick={e => deletePool(e, item?.poolId, item?._id)}>
                                            Delete
                                          </Dropdown.Item> */}
                                          <Dropdown.Item
                                            disabled={item?.isDeleted === true ? true : false}
                                            onClick={() => {
                                              featureHandle(item.featureStatus, item?.poolId);
                                            }}
                                          >
                                            Feature
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <td colSpan={7} className='text-center'>
                            <span className='text-light text-center'>No Record Found</span>
                          </td>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </>
              )}
            </Card.Body>
          </Card>
          {currentItems && currentItems?.length > 0 ? (
            <div className='mt-3'>
              <ReactPaginate
                nextLabel='next >'
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                marginPagesDisplayed={2}
                pageCount={pageCount}
                previousLabel='< previous'
                pageClassName='page-item'
                pageLinkClassName='page-link'
                previousClassName='page-item'
                previousLinkClassName='page-link'
                nextClassName='page-item'
                nextLinkClassName='page-link'
                breakLabel='...'
                breakClassName='page-item'
                breakLinkClassName='page-link'
                containerClassName='pagination'
                activeClassName='active'
                renderOnZeroPageCount={null}
              />
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      {show && <ApproveReject show={show} handleModal={handleModal} currentPoolData={currentPoolData} />}
      {scamShow && (
        <Modal
          show={scamShow}
          className='d-flex align-items-center product-modal'
          onHide={() => {
            setScamShow(false);
          }}
        >
          <Form onSubmit={e => markScam(e)}>
            <Modal.Header closeButton>
              <Modal.Title>Mark Scam</Modal.Title>
            </Modal.Header>
            <Modal.Body className='' style={{ width: '450px' }}>
              <Form.Group className='mb-3 input-wrapeper' controlId='formBasicEmail'>
                <Form.Label>Title</Form.Label>
                <Form.Control type='text' value={scamState?.title} disabled />
              </Form.Group>
              <Form.Group className='mb-3 input-wrapeper' controlId='formBasicEmail'>
                <Form.Label>Message</Form.Label>
                <Form.Control
                  style={{ resize: 'none' }}
                  as='textarea'
                  type='text'
                  rows='3'
                  placeholder='Message'
                  value={scamState.message}
                  onChange={e => {
                    setScamState({ ...scamState, message: e.target.value });
                  }}
                  required
                />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button
                className='btn-light-blue'
                onClick={e => {
                  setScamShow(false);
                }}
              >
                Cancel
              </Button>
              <Button className='btn-blue' type='submit'>
                Save
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      )}
      {loading ? <ModalLoading /> : <></>}
      {unScamShow && (
        <Modal
          show={unScamShow}
          className='d-flex align-items-center product-modal'
          onHide={() => {
            setUnScamShow(false);
          }}
        >
          <Form onSubmit={e => unMarkScam(e)}>
            <Modal.Header closeButton>
              <Modal.Title>UnMark Scam</Modal.Title>
            </Modal.Header>
            <Modal.Body className='' style={{ width: '450px' }}>
              <Form.Group className='mb-3 input-wrapeper' controlId='formBasicEmail'>
                <Form.Label>Title</Form.Label>
                <Form.Control type='text' value={scamState?.title} disabled />
              </Form.Group>
              <Form.Group className='mb-3 input-wrapeper' controlId='formBasicEmail'>
                <Form.Label>Message</Form.Label>
                <Form.Control style={{ resize: 'none' }} as='textarea' type='text' rows='3' placeholder='Message' value={scamState.message} disabled />
              </Form.Group>
              <Form.Group className='switch-wrapper mb-3'>
                <Form.Label>Status</Form.Label>
                <div className='switch'>
                  <input
                    id='switch-1'
                    type='checkbox'
                    className='switch-input'
                    checked={scamState.scamStatus ? true : false}
                    onChange={e => {
                      setScamState({
                        ...scamState,
                        scamStatus: e.target.checked,
                      });
                    }}
                  />
                  <label htmlFor='switch-1' className='switch-label'>
                    {/* Switch */}
                  </label>
                </div>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button
                className='btn-light-blue'
                onClick={e => {
                  setUnScamShow(false);
                }}
              >
                Cancel
              </Button>
              <Button className='btn-blue' type='submit'>
                UnMark
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      )}
    </div>
  );
}

export default ManageEndedPools;
