import {
  CREATE_POOL,
  POOLS_LISTING,
  ALL_POOLS_LISTING,
  POOL_BY_ID,
  UPDATE_POOL,
  DELETE_POOL,
  PLACE_POOL_ORDER,
  POOL_ORDERS,
  SETTLE_POOL_CREATE,
  CREATE_CLAIM_ORDER,
  UPDATE_POOL_STATUS,
  UPDATE_STATE,
  ADD_SCAM_POOL,
  EDIT_SCAM_POOL,
  CANCEL_POOL,
  UPDATE_FEATURE_STATUS,
  ADD_TO_WHITELIST,
  GET_WHITELIST_ADDRESSES
} from './pool.types';

const initialState = {
  poolsList: [],
  whiteListAddresses: [],
  allPoolsListing: [],
  pool: {},
  orders: [],
  settlePools: [],
  claimedOrders: [],
  scamPools: [],
  cancelledPool: {},
  editScamPool: {},
  pagination: {},
  delPool: {},
  updatedPool: null,
  poolStatus: null,
  updatedCall: false,
  delAuth: false,
  success: false,
  claimStatus: false,
  settlePoolStatus: false,
  isScamPool: false,
  isEditScamPool: false,
  featureStatus: Boolean,
  featureSuccess: false,
  isCancelled: false
};

const poolReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_POOL:
      return {
        ...state,
        poolsList: [...state.poolsList, action.payload],
      };
    case SETTLE_POOL_CREATE:
      return {
        ...state,
        settlePools: [...state.settlePools, action.payload],
        settlePoolStatus: true,
        success: true
      };
    case CREATE_CLAIM_ORDER:
      return {
        ...state,
        claimedOrders: [...state.claimedOrders, action.payload],
        claimStatus: true
      }
    case ADD_SCAM_POOL:
      return {
        ...state,
        scamPools: [...state.scamPools, action.payload],
        isScamPool: true
      }
    case EDIT_SCAM_POOL:
      return {
        ...state,
        scamPools: [...state.scamPools, action.payload],
        isEditScamPool: true
      }
    case POOLS_LISTING:
      return {
        ...state,
        poolsList: action.payload.data?.pools,
        pagination: action.payload.data?.pagination
      };
    case ALL_POOLS_LISTING:
      return {
        ...state,
        allPoolsListing: action.payload.data?.pools,
        pagination: action.payload.data?.pagination
      };
    case POOL_BY_ID:
      return {
        ...state,
        pool: action.payload.pool
      };
    case UPDATE_POOL:
      return {
        ...state,
        updatedPool: action.payload,
        updatedCall: true
      };
    case DELETE_POOL:
      return {
        ...state,
        // poolsList: state.poolsList.filter(
        //   (pool) => pool._id !== action.payload
        // ),
        delPool: action.payload,
        delAuth: true
        // delPerm: action.payload,
      };
    case CANCEL_POOL:
      return {
        ...state,
        cancelledPool: action.payload,
        isCancelled: true
      }
    case PLACE_POOL_ORDER:
      return {
        ...state,
        orders: [...state.orders, action.payload],
        success: true
      };
    case POOL_ORDERS:
      return {
        ...state,
        orders: action.payload.order
      };
    case UPDATE_POOL_STATUS:
      return {
        ...state,
        poolStatus: action.payload
      };
    case UPDATE_FEATURE_STATUS:
      return {
        ...state,
        featureStatus: action.payload,
        featureSuccess: true
      };
    case ADD_TO_WHITELIST:
      return {
        ...state,
        whiteListAddresses: [...state.whiteListAddresses, action.payload],
      };
    case GET_WHITELIST_ADDRESSES:
      return {
        ...state,
        whiteListAddresses: action.payload?.whited,
      };
    case UPDATE_STATE:
      return {
        ...state,
        success: false,
        isScamPool: false,
        isEditScamPool: false,
        claimStatus: false,
        settlePoolStatus: false,
        featureSuccess: false,
        delAuth: false,
        isCancelled: false
      };
    default:
      return {
        ...state
      };
  }
};

export default poolReducer;
