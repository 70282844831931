import React, { useState } from 'react'
import { Modal, Button, Form } from 'react-bootstrap'
import { updateStatus } from '../../../redux/pool/pool.action'
// import axios from 'axios'
import Alerts from '../../Alerts/alert'
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import ImageReviewerModal from './ImageReviewerModal';

function ApproveReject({ show, handleModal, currentPoolData }) {

   const dispatch = useDispatch()
   const [alert, setAlert] = useState('')
   const [show1, setShow1] = useState(false);

   const handleModal2 = () => {
      setShow1(false);
   };

   const handleClose = () => {
      handleModal()
   }

   const handleApprove = () => {
      dispatch(updateStatus(currentPoolData?.poolId, { poolStatus: true }))
      Swal.fire('KYC!', 'KYC updated', 'success');

      handleClose()
      setTimeout(() => {
         setAlert("")
      }, 3000)
   }

   const handleReject = () => {
      dispatch(updateStatus(currentPoolData?.poolId, { poolStatus: false }))
      Swal.fire('KYC!', 'KYC updated', 'success');
      handleClose()
      setTimeout(() => {
         setAlert("")
      }, 3000)
   }

   const handleImageClick = () => {
      // setShow1(true);

      Swal.fire({
         width: 999,
         height: 700,
         imageUrl: `https://ipfs.io/ipfs/${currentPoolData?.identityDoc}`,
         imageWidth: 999,
         imageHeight: 700,
         imageAlt: 'Custom image',
         showConfirmButton: false,
         confirmButtonColor: '#F24A17',
         confirmButtonText: 'X',
         showCloseButton: true,
         closeButtonColor: '#F24A17',
         padding: 20,
      })
   }

   return (
      <>
         <Modal show={show} onHide={handleClose} className='product-modal d-flex align-items-center' backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
               <Modal.Title>Approve / Reject</Modal.Title>
               {/* <button type='button' className='close-btn' onClick={handleClose} aria-label='Close'>×</button> */}
            </Modal.Header>
            <Modal.Body>
               <Form className='form-border'>
                  <Form.Group className='mb-3' controlId='FaqTitle'>
                     <Form.Label>Name</Form.Label>
                     <Form.Control type='text' name='name' value={currentPoolData?.name} disabled />
                  </Form.Group>
                  <Form.Group className='mb-3' controlId='FaqTitle'>
                     <Form.Label>Email</Form.Label>
                     <Form.Control type='text' name='email' value={currentPoolData?.email} disabled />
                  </Form.Group>
                  <Form.Group className='mb-3' controlId='FaqTitle'>
                     <Form.Label>Phone</Form.Label>
                     <Form.Control type='text' name='phone' value={currentPoolData?.phone} disabled />
                  </Form.Group>
                  <Form.Group className='mb-3' controlId='FaqTitle'>
                     <Form.Label>Message</Form.Label>
                     <Form.Control type='text' name='phone' value={currentPoolData?.message} disabled />
                  </Form.Group>
                  <Form.Group className='mb-3' controlId='FaqTitle'>
                     <Form.Label>Document</Form.Label>
                     <div className='modal-last-img'>
                        <img src={`https://ipfs.io/ipfs/${currentPoolData?.identityDoc}`} alt="doc" className="img-fluid" width="100" onClick={handleImageClick} />
                     </div>
                  </Form.Group>
               </Form>
            </Modal.Body>
            <Modal.Footer>
               {alert !== '' ? (
                  <Alerts variant='success' className='msg-box-alert' message={alert} show={true} />
               ) : (<></>)
               }
               {
                  currentPoolData?.poolStatus === true ?
                  <>
                     <Button className='btn-light-blue text-capitalize' disabled>
                        Reject
                     </Button>
                     <Button className='btn-danger text-capitalize' disabled>
                        Approved
                     </Button>
                  </>
                  :
                  <>
                     <Button className='btn-light-blue text-capitalize' type='submit' onClick={handleReject}>
                        Reject
                     </Button>
                     <Button className='btn-blue text-capitalize' type='submit' onClick={handleApprove}>
                        Approve
                     </Button>
                  </>
               }
               
            </Modal.Footer>
         </Modal>

         {show1 && <ImageReviewerModal show1={show1} handleModal2={handleModal2} imgData={currentPoolData?.identityDoc} />}
      </>
   )
}

export default ApproveReject