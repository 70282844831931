import React, { useEffect, useState } from 'react';
import Slider from "react-slick";
import { Image } from 'react-bootstrap';
// import srImageOne from "../../assets/images/sr-img-one.jpg";
import { Link } from 'react-router-dom';
import { encode } from 'js-base64';
// import { faBullseye } from '@fortawesome/free-solid-svg-icons';

const LandingSlick = ({ pools }) => {

    const [trendingPools, setTrendingPools] = useState([]);
    var settings = {
        dots: false,
        infinite: true,
        arrows: false,
        speed: 2500,
        slidesToShow: 6,
        slidesToScroll: 1,
        loop: true,
        autoplaySpeed: 500,
        pauseOnHover: false,
        cssEase: 'linear',
        swipeToSlide: true,
        autoplay: true,
        responsive: [
            {
              breakpoint: 1300,
              settings: {
                slidesToShow: 4,
              }
            },
            {
              breakpoint: 991,
              settings: {
                slidesToShow: 3,
              }
            },
            {
                breakpoint: 767,
                settings: {
                  slidesToShow: 2,
                }
              },
            {
              breakpoint: 575,
              settings: {
                slidesToShow: 1,
              }
            }
          ]
    };

    
    useEffect(() => {
        getData()
        // eslint-disable-next-line
    }, [pools]);

    const getData = () => {
        const tempArray = [];
        pools?.length > 0 && pools.filter(res => res.featureStatus === true).forEach(item => {
            tempArray.push(item);
        });
        setTrendingPools(tempArray)
    }

    const handleLogoScroll = () => {
        window.scroll(0, 0)
    }

    return (
        <div className='slick-carousel'>
            <div className="slick-carousel-heading text-center mb-5">
                <h3>TRENDING</h3>
            </div>
            {/* statsi code */}
            {trendingPools?.length > 0 ?
                <>
                    {trendingPools.length <= 6 ?
                        <div className='slick-carousel-boxes d-flex justify-content-center'>
                            {trendingPools.map((item, index) => {
                                return (
                                    <div className='slick-carousel-boxes-item' key={index}>
                                        <Link to={`/${"pool"}/${encode(item.poolId)}`} onClick={handleLogoScroll}>
                                            <div className='sr-item-content-wrapper d-flex align-items-center'>
                                                <div className='sr-item-img'>
                                                    <Image src={`https://ipfs.io/ipfs/${item.logo}`} className="img-fluid" />
                                                </div>
                                                <div className='sr-item-content'>
                                                    <p>{item.title}</p>
                                                    <h5>{item?.ptSymbol}</h5>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                )
                            })
                            }
                        </div>
                        :
                        <Slider {...settings}>
                            {trendingPools.map((item, index) => {
                                return (
                                    <div className='sr-item' key={index}>
                                        <Link to={`/${"pool"}/${encode(item.poolId)}`} onClick={handleLogoScroll}>
                                            <div className='sr-item-content-wrapper d-flex align-items-center'>
                                                <div className='sr-item-img'>
                                                    <Image src={`https://ipfs.io/ipfs/${item.logo}`} className="img-fluid" />
                                                </div>
                                                <div className='sr-item-content'>
                                                    <p>{item.title}</p>
                                                    <h5>{item?.ptSymbol}</h5>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                )
                            })
                            }
                        </Slider>
                    }
                </>
                :
                <div className='text-center text-light'>
                    <h5>No Trending Pools Found</h5>
                </div>
            }
        </div>
    )
}

export default LandingSlick
