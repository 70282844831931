import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";

const PieChart = ({ startedPools, upcomingPools, endedPools }) => {

   const [chartData, setChartData] = useState({ series: [], labels: [] });

   const pieChartOptions = {
      series: chartData.series,
      options: {
         labels: chartData.labels,
         chart: {
            type: 'donut'
         },
         responsive: [{
            breakpoint: 480,
            options: {
               chart: {
                  width: 200
               },
            }
            , legend: {
               position: 'bottom'
            }
         }]
      },
   }

   const fetchPoolData = () => {
      let dummySeries = [startedPools?.length, upcomingPools?.length, endedPools?.length];
      let dummyLabels = ['Live', 'Upcoming', 'Ended']
      setChartData({ ...chartData, series: dummySeries, labels: dummyLabels })
   };

   useEffect(() => {
      fetchPoolData();
      // eslint-disable-next-line
   }, [startedPools, upcomingPools, endedPools]);

   return (
      <>
         <div className='circle-chart'>
            <Chart className="circle-chart-img" options={pieChartOptions.options} series={pieChartOptions.series} type="donut" />
         </div>
      </>
   )
}

export default PieChart