import React, { useEffect, useState } from "react";
// import { Link } from 'react-router-dom';
import { Accordion } from "react-bootstrap";
// import { TailSpin } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import * as FAQActions from "../../redux/faq/faq.action";
import { bindActionCreators } from 'redux'
import FullPageLoader from "../Loaders/fullpageloader";

function Faqs() {

  const dispatch = useDispatch()
	const { faqListing } = bindActionCreators(FAQActions, dispatch)
  const faqs = useSelector((state) => state.faq?.faqsList)
  const [loader, setLoader] = useState(false);

  const fetchData = async () => {
		let res = await faqListing()
		if (res) {
			setLoader(false)
		}
	}

	useEffect(() => {
		setLoader(true)
		fetchData()
		// eslint-disable-next-line
	}, []);

  return (
    <>
      {loader ? (
        // <div className="faq-spin">
        //   <TailSpin height="100" width="100" color="#46bdf4" ariaLabel="loading" /> </div>
        <FullPageLoader/>
      ) : (
        <div className="container">
          <div className="section_padding">
            <div className="terms-page bg-transparent">
              <div className="page-heading my-lg-4 my-2">
                <h2 className="text-white text-capitalize mt-4 text-center">
                  FAQs
                </h2>
              </div>
              {faqs.length > 0 ? (
                faqs.map((faq, ind) => (
                  <Accordion className="ido-front-side-faqs" key={ind}>
                    <Accordion.Item>
                      <Accordion.Header>
                        <h4 className="card-title text-white mb-0">
                          {faq.title}
                        </h4>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p className="card-text" dangerouslySetInnerHTML={{ __html: faq.description }}></p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                )))
                : (
                  <div className="text-center">
                    <span className='text-light text-center'>No Record Found</span>
                  </div>
                )
              }
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Faqs;
