import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import { Badge } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
// import { TailSpin } from "react-loader-spinner";
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from "react-redux";
import * as CMSPagesAction from "../../../redux/cms/cms.action";
import { bindActionCreators } from "redux";
import { useState } from "react";
import FullPageLoader from "../../Loaders/fullpageloader";

function ManageCMSPages() {

  const dispatch = useDispatch()
  const { cmsPageListing, updateState, deleteCMSPage } = bindActionCreators(CMSPagesAction, dispatch)
  const cmsPages = useSelector((state) => state?.cms.cmsPageList)
  const success = useSelector((state) => state.cms?.success)
  const [loader, setLoader] = useState(false);

  const confirmDelete = (id) => {
    Swal.fire({
      title: '',
      text: "Are you sure want to delete it?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        deleteCMSPage(id)
        Swal.fire(
          'Deleted!',
          'Pool has been deleted.',
          'success'
        )
      }
    })
  }

  const fetchData = async () => {
    setLoader(true)
    let res = await cmsPageListing()
    if (res) {
      setLoader(false)
    }
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (success) {
      cmsPageListing()
      updateState()
    }
    // eslint-disable-next-line
  }, [success]);

  return (
    <>
      <div className="mutopad-w-100">
        {loader ? (
          <Card className="pt-lg-4 pt-3 h-auto" id="style-6">
            <Card.Body>
              <div className="text-center faq-loader">
                {/* <TailSpin height="100" width="100" color="#46bdf4" ariaLabel="loading" /> */}
                <FullPageLoader/>
              </div>
            </Card.Body>
          </Card>
        ) :
          <div className="content">
            <div className="manage-pools-pg">
              <Card className="pt-lg-4 pt-3 h-auto" id="style-6">
                <Card.Header className="titles-sett">
                  <h2 className="text-shadow">Manage CMS Pages</h2>
                  <div className="sm-right-btn">
                    <Link className="d-inline light-blue-btn text-capitalize" to='/admin/create-cmspage'>
                      <FontAwesomeIcon className="add-icon" icon={faPlus} /> Create Page
                    </Link>
                  </div>
                </Card.Header>
                <Card.Body>
                  {cmsPages?.length > 0 ? (
                    <>
                      <div className="table-responsive manage-pools">
                        <Table>
                          <thead>
                            <tr className="text-center">
                              <th>Page Title</th>
                              <th>Meta Title</th>
                              <th>ShowLinkInHeader</th>
                              <th>ShowLinkInFooter</th>
                              <th>PageStatus</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {cmsPages.map((item, index) => {
                              return (
                                <tr key={index}>
                                  <td className='text-capitalize'>{item.pageTitle?.split("-").join(" ")}</td>
                                    {
                                      item.metaTitle ? <td> {item.metaTitle} </td> : <td> --</td>
                                    }
                                  <td>
                                    {item.showLinkInHeader === true ? (
                                      <Badge className="px-2 py-1" bg="info">
                                        Active
                                      </Badge>
                                    ) : (
                                      <Badge className="px-2 py-1" bg="secondary">
                                        Disabled
                                      </Badge>
                                    )}{" "}
                                  </td>
                                  <td>
                                    {item.showLinkInFooter === true ? (
                                      <Badge className="px-2 py-1" bg="info">
                                        Active
                                      </Badge>
                                    ) : (
                                      <Badge className="px-2 py-1" bg="secondary">
                                        Disabled
                                      </Badge>
                                    )}{" "}
                                  </td>
                                  <td>
                                    {item.pageStatus === true ? (
                                      <Badge className="px-2 py-1" bg="info">
                                        Active
                                      </Badge>
                                    ) : (
                                      <Badge className="px-2 py-1" bg="secondary">
                                        Disabled
                                      </Badge>
                                    )}{" "}
                                  </td>
                                  <td>
                                    <Link className="btn btn-success btn-sm me-2" to={`/admin/edit-cmspage/${item._id}`}>
                                      Edit
                                    </Link>
                                    <button className="btn btn-danger btn-sm me-2" onClick={() => { confirmDelete(item._id) }}>
                                      Delete
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </div>
                    </>
                  ) : (
                    <div className="text-center">
                      <span className='text-light text-center'>No Record Found</span>
                    </div>
                  )}
                </Card.Body>
              </Card>
            </div>
          </div>
        }
      </div>
    </>
  );
}

export default ManageCMSPages;