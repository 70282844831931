//eslint-disable-file
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import cmsPageReducer from './cms/cms.reducer';
import commentReducer from './comment/comment.reducer';
import faqReducer from './faq/faq.reducer';
import poolReducer from './pool/pool.reducer';
import settingReducer from './setting/setting.reducer';

const middleware = [thunk];
const reducer = combineReducers({
  pool: poolReducer,
  faq: faqReducer,
  cms: cmsPageReducer,
  setting: settingReducer,
  comment: commentReducer
});

const store = createStore(
  reducer,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
