import React from 'react'
import { Col, Row, Image } from 'react-bootstrap';
import currimgone from "../../assets/images/curr-img-one.png";
import currimgtwo from "../../assets/images/curr-img-two.png";
// import currimgthree from "../../assets/images/curr-img-three.png";
// import currimgfour from "../../assets/images/curr-img-four.png";
// import currimgfive from "../../assets/images/curr-img-five.png";
// import currimgsix from "../../assets/images/curr-img-six.png";
// import currimgseven from "../../assets/images/curr-img-seven.png";
// import currimgeight from "../../assets/images/curr-img-eight.png";
// import currimgnine from "../../assets/images/curr-img-nine.png";

const LandingBoxes = () => {
    return (
        <div className='landing-boxes'>
            <div className='container'>
                <Row className='mb-5'>
                    <Col lg="3" className='mb-lg-0 mb-4'>
                        <div className='landing-boxes-content text-center'>
                            <h3>$22.09M</h3>
                            <p className='mb-0'>RAISED CAPITAL</p>
                        </div>
                    </Col>
                    <Col lg="3" className='mb-lg-0 mb-4'>
                        <div className='landing-boxes-content text-center'>
                            <h3>118+</h3>
                            <p className='mb-0'>LAUNCHED PROJECTS</p>
                        </div>
                    </Col>
                    <Col lg="3" className='mb-lg-0 mb-4'>
                        <div className='landing-boxes-content text-center'>
                            <h3>$169.48M</h3>
                            <p className='mb-0'>PROJECTS' VOLUME (30D)</p>
                        </div>
                    </Col>
                    <Col lg="3" className='mb-lg-0 mb-4'>
                        <div className='landing-boxes-content text-center'>
                            <h3>$1.39B</h3>
                            <p className='mb-0'>PROJECTS' FDV MKT CAP</p>
                        </div>
                    </Col>
                </Row>
                <div className='landing-logos text-center'>
                    <h3 className='mb-4'>Supported blockchains</h3>
                    <div className='landing-logos-content d-flex justify-content-center flex-wrap'>
                        <div className='landing-logos-content-logos position-relative'>
                            <Image src={currimgone} className="img-fluid" />
                            <span>Ethereum</span>
                        </div>
                        <div className='landing-logos-content-logos position-relative'>
                            <Image src={currimgtwo} className="img-fluid" />
                            <span>BNB Chain</span>
                        </div>
                        {/* <div className='landing-logos-content-logos position-relative'>
                            <Image src={currimgthree} className="img-fluid" />
                            <span>Solana</span>
                        </div>
                        <div className='landing-logos-content-logos position-relative'>
                            <Image src={currimgfour} className="img-fluid" />
                            <span>Arbitrum</span>
                        </div>
                        <div className='landing-logos-content-logos position-relative'>
                            <Image src={currimgfive} className="img-fluid" />
                            <span>Near</span>
                        </div>
                        <div className='landing-logos-content-logos position-relative'>
                            <Image src={currimgsix} className="img-fluid" />
                            <span>Fantom</span>
                        </div>
                        <div className='landing-logos-content-logos position-relative'>
                            <Image src={currimgseven} className="img-fluid" />
                            <span>Avalanche</span>
                        </div>
                        <div className='landing-logos-content-logos position-relative'>
                            <Image src={currimgeight} className="img-fluid" />
                            <span>Polygon</span>
                        </div>
                        <div className='landing-logos-content-logos position-relative'>
                            <Image src={currimgnine} className="img-fluid" />
                            <span>Aptos</span>
                        </div> */}
                    </div>
                </div>
            </div>                    
        </div>               
    )
}

export default LandingBoxes
