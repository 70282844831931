import React, { useEffect, useState } from 'react'
import Card from 'react-bootstrap/Card'
import { Row, Col, Form } from 'react-bootstrap'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import * as settingAction from "../../../redux/setting/setting.action";
import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from 'react-helmet';
// import { TailSpin } from 'react-loader-spinner';
import FullPageLoader from '../../Loaders/fullpageloader';

function Setting() {

  const dispatch = useDispatch()
  const result = useSelector((state) => state?.setting)
  const { getSetting, updateSetting } = bindActionCreators(settingAction, dispatch)
  const [loader, setLoader] = useState(false);

  const [settingDetail, setSettingDetails] = useState({
    siteTitle: '',
    siteDescription: '',
    metaTitle: '',
    metaKeyword: '',
    telegramLink: '',
    mediumLink: '',
    twitterLink: '',
    websiteLink: '',
    appLink: '',
    podCastLink: '',
    metaDescription: ''
  })

  const [formValidate, setFormValidate] = useState({})

  const handleChange = e => {
    const { name, value } = e.target
    setSettingDetails({ ...settingDetail, [name]: value })

    if (!!formValidate[name]) setFormValidate({
      ...formValidate,
      [name]: null
    })
  }

  const findFormErrors = () => {
    const { siteTitle, telegramLink, mediumLink, twitterLink, websiteLink, appLink, podCastLink } = settingDetail
    const newErrors = {}

    const regex = new RegExp(
      "https?://(www.)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?"
    );

    // siteTitle errors
    if (siteTitle === '') newErrors.siteTitle = 'cannot be blank!'
    // siteDescription errors
    // if (!siteDescription || siteDescription === '') newErrors.siteDescription = 'cannot be blank!'
    // metaTitle errors
    // if (!metaTitle || metaTitle === '') newErrors.metaTitle = 'cannot be blank!'
    // metaKeyword errors
    // if (!metaKeyword || metaKeyword === '') newErrors.metaKeyword = 'cannot be blank!'
    // telegramLink errors
    if (telegramLink && !regex.test(telegramLink)) newErrors.telegramLink = 'Please Enter a Valid Link!'
    // mediumLink errors
    if (mediumLink && !regex.test(mediumLink)) newErrors.mediumLink = 'Please Enter a Valid Link!'
    // twitterLink errors
    if (twitterLink && !regex.test(twitterLink)) newErrors.twitterLink = 'Please Enter a Valid Link!'
    // websiteLink errors
    if (websiteLink && !regex.test(websiteLink)) newErrors.websiteLink = 'Please Enter a Valid Link!'
    // appLink errors
    if (appLink && !regex.test(appLink)) newErrors.appLink = 'Please Enter a Valid Link!'
    // podCastLink errors
    if (podCastLink && !regex.test(podCastLink)) newErrors.podCastLink = 'Please Enter a Valid Link!'
    // metaDescription errors
    // if (!metaDescription || metaDescription === '') newErrors.metaDescription = 'cannot be blank!'

    return newErrors
  }

  const fetchData = async () => {
    setLoader(true)
    let res = await getSetting()
    if (res) {
      setLoader(false)
    }
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (result) {
      setSettingDetails(result?.setting)
    }
  }, [result])

  const SaveSettings = async () => {
    // const {siteTitle,}

    // get our new errors
    const newErrors = findFormErrors()
    // Conditional logic:
    if (Object.keys(newErrors).length > 0) {
      // We got errors!
      setFormValidate(newErrors)
    } else {
      const data = {
        siteTitle: settingDetail.siteTitle,
        metaTitle: settingDetail.metaTitle,
        metaKeyword: settingDetail.metaKeyword,
        siteDescription: settingDetail.siteDescription,
        metaDescription: settingDetail.metaDescription,
        twitterLink: settingDetail.twitterLink,
        telegramLink: settingDetail.telegramLink,
        mediumLink: settingDetail.mediumLink,
        appLink: settingDetail.appLink,
        podCastLink: settingDetail.podCastLink,
        websiteLink: settingDetail.websiteLink
      }
      setLoader(true)
      let res = await updateSetting(data)
      if (res) {
        setLoader(false)
        getSetting()
      }
    }
  }

  return (
    <div className='mutopad-w-100'>
      {loader ? (
        <Card className="pt-lg-4 pt-3 h-auto" id="style-6">
          <Card.Body>
            <div className="text-center faq-loader">
              {/* <TailSpin height="100" width="100" color="#46bdf4" ariaLabel="loading" /> */}
              <FullPageLoader/>
            </div>
          </Card.Body>
        </Card>
      ) :
        <div className='content'>
          <div className='cms-pg'>
            <Helmet>
              <title>{settingDetail?.siteTitle === '' ? "MutoPad Launchpad" : settingDetail?.siteTitle}</title>
            </Helmet>
            <Card className='pt-lg-4 pt-3 h-100' id="style-6">
              <Card.Header className='titles-sett text-center'>
                <div className='d-flex flex-sm-row flex-column justify-content-sm-between w-100'>
                  <h2 className='text-shadow mb-sm-0 mb-2'> Site Settings</h2>
                  <div>
                    <div className='sm-right-btn'><button className='d-inline light-blue-btn text-capitalize mb-sm-0 mb-2' onClick={SaveSettings}>
                      Save Settings</button>
                    </div>
                  </div>
                </div>
              </Card.Header>
              <Card.Body>
                <div className='cms-pages-style'>
                  <div className='container'>
                    <Row>
                      <Col sm={12}>
                        <div className='cms-pages-style text-secondary'>
                          <Form>
                            <Form.Group className='mb-2'>
                              <Form.Label>Site Title</Form.Label>
                              <Form.Control
                                type='text'
                                name='siteTitle'
                                onChange={handleChange}
                                placeholder='Enter Name'
                                className={'text-white'}
                                value={settingDetail?.siteTitle}
                                isInvalid={!!formValidate.siteTitle}
                              />
                              <Form.Control.Feedback type='invalid'>{formValidate.siteTitle}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group
                              className='mb-3'
                              controlId='exampleForm.ControlTextarea1'
                            >
                              <Form.Label>Meta Title</Form.Label>
                              <Form.Control
                                type='text'
                                name='metaTitle'
                                className={'text-white'}
                                onChange={handleChange}
                                placeholder='Enter metaTitle'
                                value={settingDetail?.metaTitle}
                                isInvalid={!!formValidate.metaTitle}
                              />
                              <Form.Control.Feedback type='invalid'>{formValidate.metaTitle}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group
                              className='mb-3'
                              controlId='exampleForm.ControlTextarea1'
                            >
                              <Form.Group
                                className='mb-3'
                                controlId='exampleForm.ControlTextarea1'
                              >
                                <Form.Label>Meta keywords</Form.Label>
                                <Form.Control
                                  type='text'
                                  name='metaKeyword'
                                  onChange={handleChange}
                                  className={'text-white'}
                                  placeholder='Enter metaKeyword'
                                  value={settingDetail?.metaKeyword}
                                  isInvalid={!!formValidate.metaKeyword}
                                />
                                <Form.Control.Feedback type='invalid'>{formValidate.metaKeyword}</Form.Control.Feedback>
                              </Form.Group>
                              <Form.Label>Site Description</Form.Label>
                              <span className="ido-ckeditor setting-ckeditor">
                                <CKEditor editor={ClassicEditor} data={settingDetail?.siteDescription ? settingDetail.siteDescription : ''}
                                  onChange={(event, editor) => {
                                    const data = editor.getData(); setSettingDetails((prevState) => ({ ...prevState, siteDescription: data }))
                                  }} />
                              </span>
                              {/* <Form.Control id="scroll-1"
                              as='textarea'
                              rows={2}
                              name='siteDescription'
                              onChange={handleChange}
                              className={'text-white'}
                              placeholder='Enter Description'
                              value={settingDetail.siteDescription}
                              isInvalid={!!formValidate.siteDescription}
                            /> */}
                              <Form.Control.Feedback type='invalid'>{formValidate?.siteDescription}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className='mb-3'>
                              <Form.Label>Meta Description</Form.Label>
                              <Form.Control id="scroll-1"
                                as='textarea'
                                rows={2}
                                name='metaDescription'
                                onChange={handleChange}
                                className={'text-white'}
                                placeholder='Enter Description'
                                value={settingDetail?.metaDescription}
                                isInvalid={!!formValidate.metaDescription}
                              />
                              <Form.Control.Feedback type='invalid'>{formValidate.metaDescription}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group
                              className='mb-3'
                              controlId='exampleForm.ControlTextarea1'
                            >
                              <Form.Label>Twitter Link</Form.Label>
                              <Form.Control
                                type='link'
                                name='twitterLink'
                                onChange={handleChange}
                                placeholder='Enter Twitter Link'
                                className={'text-white'}
                                value={settingDetail?.twitterLink}
                                isInvalid={!!formValidate.twitterLink}
                              />
                              <Form.Control.Feedback type='invalid'>{formValidate.twitterLink}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group
                              className='mb-3'
                              controlId='exampleForm.ControlTextarea1'
                            >
                              <Form.Label>Telegram Link</Form.Label>
                              <Form.Control
                                type='link'
                                onChange={handleChange}
                                name='telegramLink'
                                className={'text-white'}
                                placeholder='Enter Telegram Link'
                                value={settingDetail?.telegramLink}
                                isInvalid={!!formValidate.telegramLink}
                              />
                              <Form.Control.Feedback type='invalid'>{formValidate.telegramLink}</Form.Control.Feedback>
                            </Form.Group>{' '}
                            <Form.Group
                              className='mb-3'
                              controlId='exampleForm.ControlTextarea1'
                            >
                              <Form.Label>Youtube Link</Form.Label>
                              <Form.Control
                                type='link'
                                name='mediumLink'
                                onChange={handleChange}
                                className={'text-white'}
                                placeholder='Enter Youtube Link'
                                value={settingDetail?.mediumLink}
                                isInvalid={!!formValidate.mediumLink}
                              />
                              <Form.Control.Feedback type='invalid'>{formValidate.mediumLink}</Form.Control.Feedback>
                            </Form.Group>{' '}
                            <Form.Group
                              className='mb-3'
                              controlId='exampleForm.ControlTextarea1'
                            >
                              <Form.Label>App Link</Form.Label>
                              <Form.Control
                                type='link'
                                name='appLink'
                                onChange={handleChange}
                                className={'text-white'}
                                placeholder='Enter App Link'
                                value={settingDetail?.appLink}
                                isInvalid={!!formValidate.appLink}
                              />
                              <Form.Control.Feedback type='invalid'>{formValidate.appLink}</Form.Control.Feedback>
                            </Form.Group>{' '}
                            <Form.Group
                              className='mb-3'
                              controlId='exampleForm.ControlTextarea1'
                            >
                              <Form.Label>Linkedin Link</Form.Label>
                              <Form.Control
                                type='link'
                                name='podCastLink'
                                onChange={handleChange}
                                className={'text-white'}
                                placeholder='Enter Linkedin Link'
                                value={settingDetail?.podCastLink}
                                isInvalid={!!formValidate.podCastLink}
                              />
                              <Form.Control.Feedback type='invalid'>{formValidate.podCastLink}</Form.Control.Feedback>
                            </Form.Group>{' '}
                            <Form.Group
                              className='mb-3'
                              controlId='exampleForm.ControlTextarea1'
                            >
                              <Form.Label>Github Link</Form.Label>
                              <Form.Control
                                type='link'
                                name='websiteLink'
                                onChange={handleChange}
                                className={'text-white'}
                                placeholder='Enter Github Link'
                                value={settingDetail?.websiteLink}
                                isInvalid={!!formValidate.websiteLink}
                              />
                              <Form.Control.Feedback type='invalid'>{formValidate.websiteLink}</Form.Control.Feedback>
                            </Form.Group>{' '}
                          </Form>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Card.Body>

            </Card>
          </div>
        </div>
      }
    </div>
  )
}

export default Setting
