import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import PieChart from "./Chart"
import { Link } from 'react-router-dom'
import Barchart from "./Barchart";
import "./Chart.css";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { poolsListing } from "../../../redux/pool/pool.action";
import { useAccount } from 'wagmi'
// import { TailSpin } from "react-loader-spinner";
import FullPageLoader from "../../Loaders/fullpageloader";

function BannerAdmin({ ownerFlag }) {

  const dispatch = useDispatch()
  const { address } = useAccount()
  const [userLoginAcc, setUserLoginAcc] = useState('');
  const pools = useSelector((state) => state?.pool?.poolsList)
  const [startedPools, setStartedPools] = useState([]);
  const [upcomingPools, setUpcomingPools] = useState([]);
  const [endedPools, setEndedPools] = useState([]);
  const [flag, setFlag] = useState(false);
  const [loader, setLoader] = useState(false);

  const separateData = () => {
    let started = []
    let upcoming = []
    let ended = []
    pools?.forEach(async (el) => {
      if (el.poolStatus === true) {
        if (parseInt(el.poolStartDate) < moment(Date.now()).unix() && parseInt(el.poolEndDate) < moment(Date.now()).unix()) {
          ended.push(el)
        } else if (parseInt(el.poolStartDate) < moment(Date.now()).unix()) {
          started.push(el)
        } else {
          upcoming.push(el)
        }
      }
    })
    setStartedPools(started)
    setUpcomingPools(upcoming)
    setEndedPools(ended)
    setFlag(true)
  }

  useEffect(() => {
    if (pools?.length > 0) {
      separateData();
    }
    // eslint-disable-next-line
  }, [pools]);

  useEffect(() => {
    if (address !== undefined) {
      setUserLoginAcc(address.toLocaleLowerCase());
    }
  }, [address, userLoginAcc]);

  useEffect(() => {
    dispatch(poolsListing(userLoginAcc))
  }, [userLoginAcc, dispatch]);

  useEffect(() => {
    setLoader(true)
    if (flag) {
      separateData();
      setLoader(false)
    }
    // eslint-disable-next-line
  }, [flag]);

  return (
    <div className="mutopad-w-100">
      <div className="content">
        {/* TABLE */}
        <div className="manage-pools-pg">
          <Card className="pt-lg-4 pt-3 h-100" id="style-6">
            {/* CARD ROW */}
            <div className="row">
              <div className="col-md-12">
                <div className="panel dark-theme">
                  <div className="panel-body dashboard-cards">
                    <div className="row mb-3 shadow-cards justify-content-between align-content-center">
                      {ownerFlag === true ? (
                        <>
                          <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-3">
                            <Link to="/admin/managepools">
                              <div className="widget-metric_6 animate bg-primary box-primary-shadow">
                                <div className="right">
                                  <span className="value">
                                    Manage Pools
                                    {/* Number Of Pools - {poolAmount} */}
                                  </span>
                                  {/* <span className='title'>Users</span> */}
                                </div>
                              </div>
                            </Link>
                          </div>
                          <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-3">
                            <Link to="/admin/faqs">
                              <div className="widget-metric_6 animate blue-yellow box-primary-shadow">
                                <div className="right">
                                  <span className="value">Manage Faqs</span>
                                  {/* <span className="title">Users</span> */}
                                </div>
                              </div>
                            </Link>
                          </div>
                          <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-3">
                            <Link to="/admin/manage-cmspages">
                              <div className="widget-metric_6 animate blue-light box-primary-shadow">
                                <div className="right">
                                  <span className="value">CMS Pages</span>
                                  {/* <span className="title">2</span> */}
                                </div>
                              </div>
                            </Link>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-3">
                            <Link to="/admin/managepools">
                              <div className="widget-metric_6 animate bg-primary box-primary-shadow">
                                <div className="right">
                                  <span className="value">
                                    Manage Pools
                                    {/* Number Of Pools - {poolAmount} */}
                                  </span>
                                  {/* <span className='title'>Users</span> */}
                                </div>
                              </div>
                            </Link>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <h1 className="pool-label">Pools Stats</h1>
                  {loader && setTimeout(() =>{setLoader(false)}, 2000) ? (
                    <div className="text-center faq-loader">
                      {/* <TailSpin height="100" width="100" color="#46bdf4" ariaLabel="loading" /> */}
                    <FullPageLoader/>
                    </div>
                  ) :
                    <>
                      {startedPools?.length > 0 || upcomingPools?.length > 0 || endedPools?.length > 0 ?
                        <div className="chart-div">
                          <PieChart startedPools={startedPools} upcomingPools={upcomingPools} endedPools={endedPools} />
                          <Barchart startedPools={startedPools} upcomingPools={upcomingPools} endedPools={endedPools} />
                        </div>
                        : (
                          <div className="text-center">
                            <span className='text-light text-center'>No Record Found</span>
                          </div>
                        )
                      }
                    </>
                  }
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default BannerAdmin;
